import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import styles from './index.less';

interface PreviewRelaElementProps {
  item: any;
  onRela?: (id: string, ty: string) => void;
}

const PreviewRelaElement: React.FC<PreviewRelaElementProps> = (props) => {
  const { item, onRela } = props;

  return (
    item?.refContent && (
      <a className={styles.preview} onClick={() => onRela?.(item?.refId, item?.refType)}>
        <EyeOutlined /> 查看详情
      </a>
    )
  );
};

export default PreviewRelaElement;
