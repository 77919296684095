/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { ImageItem } from '@/utils/picControler/ImageBase';
import Mimes from '@/utils/picControler/mimes';
import { ForwardedRef, forwardRef, useCallback } from 'react';
// import { createImageList } from '../../utils/transform';
import { message } from '@sinohealth/butterfly-ui-components';
import useTransform from '../../hook/useTransform';
import style from './index.less';

interface ImageInputProps {
  multiple?: boolean;
  accept?: string;
  maxFiles?: number;
  maxSize?: number;
  onChange?: (files: Array<Promise<ImageItem>>, videoFile?: Array<File>) => void;
}

const acceptedMimes = Object.keys(Mimes)
  .map((item) => `.${item}`)
  .join(',');

const ImageInput = forwardRef((props: ImageInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { createImageList } = useTransform();

  const validateFileSize = (file: File, maxSize: number, bmpMaxSize: number): boolean => {
    console.log(file.type);
    if (file.type === 'image/bmp') {
      if (file.size > bmpMaxSize) {
        message.error(`文件 ${file.name} 超过最大允许大小 10MB`);
        return false;
      }
    } else if (file.type === 'video/mp4') {
      if (file.size > 1000 * 1024 * 1024) {
        message.error(`文件 ${file.name} 超过最大允许大小 1000MB`);
        return false;
      }
    } else if (file.size > maxSize) {
      message.error(`文件 ${file.name} 超过最大允许大小 ${maxSize / (1024 * 1024)} MB`);
      return false;
    }

    return true;
  };

  const validateFileCount = (files: FileList, maxFiles: number): boolean => {
    if (files.length > maxFiles) {
      message.error(`最多允许上传 ${maxFiles} 个文件`);
      return false;
    }
    return true;
  };

  const validateFileTypes = (files: File[], accept: string): boolean => {
    const filteredList = files.filter((file) => accept.split(',').includes(file.type));
    if (files.length !== filteredList.length) {
      message.error(`部分文件格式不支持，请上传${accept.replace(/image\//g, ' ')} 格式的图片`);
      return false;
    }
    return true;
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read file as data URL'));
        }
      };
      reader.onerror = (error) => {
        reject(new Error(`File reading error: ${error}`));
      };
    });
  };

  const handleAllPngFiles = async (pngList: File[]): Promise<File[]> => {
    const processedFiles: File[] = [];

    await Promise.all(
      pngList.map(async (el: File) => {
        // 1. 读取 PNG 文件为 Data URL
        const base64 = await fileToBase64(el);

        // 2. 创建 Image 对象
        const img: any = new Image();
        img.src = base64;

        // 3. 等待图像加载完成
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        // 4. 创建 Canvas 并绘制图像
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // 5. 从 Canvas 导出为 JPG
          const jpgDataUrl = canvas.toDataURL('image/jpeg', 0.9); // 第二个参数是质量，范围 0-1

          // 6. 将 Data URL 转换为 Blob
          const response = await fetch(jpgDataUrl);
          const blob = await response.blob();

          // 7. 创建新的 File 对象
          const newFile = new File([blob], `${el.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
          processedFiles.push(newFile);
        }
      }),
    );

    // 所有文件处理完成后，返回处理后的文件数组
    return processedFiles;
  };

  const handleChange = useCallback(
    async (event: any) => {
      const files = event.target.files;
      if (!files?.length) {
        event.target.value = '';
        return;
      }
      const maxFiles = props?.maxFiles || 50; // 默认最多允许上传5个文件
      const maxSize = props?.maxSize || 20 * 1024 * 1024; // 默认每个文件最大5MB
      const bmpMaxSize = 10 * 1024 * 1024; // BMP 文件最大10MB

      if (!validateFileCount(files, maxFiles)) {
        event.target.value = '';
        return;
      }

      const fileList: any = Array.from(files).filter(
        (file: any) => !!file && file.type.indexOf('png') === -1,
      );
      const pngList: any[] = Array.from(files).filter(
        (file: any) => !!file && file.type.indexOf('png') !== -1,
      );
      const videoList = fileList.filter((item: any) => item.type.indexOf('video') !== -1);

      // 处理所有 PNG 文件并获取处理后的文件数组
      const processedPngFiles = await handleAllPngFiles(pngList);
      // console.log({ fileList, pngList, videoList, processedPngFiles });

      // 找出所有的图片
      const imgList = fileList.filter((item: any) => item.type.indexOf('video') === -1);
      // 处理后的文件
      const list = [...imgList, ...processedPngFiles];
      // console.log({ list });
      // 检查每个文件的大小
      for (const file of [...list, ...videoList]) {
        if (!validateFileSize(file, maxSize, bmpMaxSize)) {
          event.target.value = '';
          return;
        }
      }

      // 单个时候，判断是否为视频
      // if (videoList.length > 0) {
      //   props?.onChange?.([], videoList);
      // }

      if (!validateFileTypes(list, props.accept || acceptedMimes)) {
        event.target.value = '';
        return;
      }

      if (!props?.onChange) {
        await createImageList(list, true);
      } else {
        const res = await createImageList(list);
        props?.onChange?.(res, videoList);
      }

      event.target.value = '';
    },
    [props.onChange, createImageList],
  );

  return (
    <input
      ref={ref}
      className={style.file}
      type="file"
      multiple={props.multiple}
      accept={props?.accept || acceptedMimes}
      onChange={handleChange}
    />
  );
});

export default ImageInput;
