/* eslint-disable react/no-array-index-key */
import Label from '@/components/Label';
import ReactViewer from '@/components/ReactViewer';
import { useLocales } from '@/Locales';
import OcrImage from '@/pages/patient/detail/components/OcrImage';
import { previewFile } from '@/utils';
import { Button } from '@sinohealth/butterfly-ui-components';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import SingleCompress from '../SingleCompress';
// @ts-ignore
import Sortable from 'sortablejs';
import { OcrContext } from '../PatientTabs/components/TabItem';
import styles from './index.less';

const RecordImgUpload = (props: any) => {
  const {
    value,
    recordId,
    onChange,
    disabled,
    onEdit,
    label = '报告图片',
    accept = 'image/jpeg,image/jpg,image/bmp,image/png',
    fileType,
  } = props;
  const { t } = useLocales();
  const [params] = useSearchParams();
  const patientId = params.get('id') || params.get('patientId');
  const [images, setImages] = useState<string[]>([]);
  const { loading, recognitTaskId, finished, setReportText, setOcrDetail, setOcrConfig } =
    useContext(OcrContext);
  // 所有的预览地址
  const [viewImages, setViewImages] = useState<ImageDecorator[]>([]);
  const [viewImagesIndex, setViewImagesIndex] = useState(0);
  const [canOcrFlag, setCanOcrFlag] = useState(true); // 是否可以点击ocr识别
  const sortDargRef = useRef<any>(null);

  useEffect(() => {
    setImages(value ? value.split(',') : []);
    setViewImagesIndex(0);
  }, [value]);

  const onUploadSuccess = (info?: string | string[]) => {
    // const imgVar = info.file?.response?.data;
    if (!info) return;
    console.log('onUploadSuccess', images, info);
    if (Array.isArray(info)) {
      onChange([...images, ...info].join(','));
    } else {
      onChange([...images, info].join(','));
    }
  };

  const handleOnDelete = (imgSrc: string, index: number) => {
    const imgs = [...images];
    // .filter((item) => item !== imgSrc);
    imgs.splice(index, 1);
    // console.log({ imgs, index });
    onChange(imgs.join(','));
  };

  const handleOnUpdate = (index: number, imgSrc: string) => {
    const imgs = [...images];
    // imgs[index] = imgSrc; // 替换为新的图片地址[imgSrc]
    imgs.splice(index, 1, imgSrc);
    onChange(imgs.join(','));
  };

  const handleOnStartOcr = async () => {
    try {
      startOcrFn(recognitTaskId);
    } catch (error) {
      console.log('error', error);
    }
  };

  const startOcrFn = (taskId: string) => {
    const suffix = ['.jpg', '.jpeg', '.png', '.bmp'];
    setOcrDetail([]);
    setReportText('');
    setOcrConfig({
      open: true,
      data: {
        patientId,
        reportType: fileType,
        recordId,
        recognitionTaskId: taskId,
        filePaths:
          fileType === 'imageReport'
            ? images.filter((el) => suffix.some((suff) => el.toLowerCase().endsWith(suff)))
            : images,
      },
    });
  };

  const handleOnEnd = _.debounce(() => {
    const list: any[] = [];
    for (let i = 0; i < sortDargRef.current.children.length; i += 1) {
      const item = sortDargRef.current.children[i];
      if (item.id) {
        list.push(item.id);
      }
    }
    // console.log('handleOnEnd', list);
    onChange(list.join(','));
  }, 100);

  useEffect(() => {
    let sortable: any = null;
    if (images.length && sortDargRef.current && !disabled) {
      sortable = new Sortable(sortDargRef.current, {
        // handle: `.${item?.value}`,
        // sort: false, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
        // direction: 'vertical',
        disabled,
        group: 'shared', // 共享同一个组名，允许跨列表拖拽
        animation: 10, // ms, number 单位：ms，定义排序动画的时间
        filter: '[data-no-drag="true"]', // 禁止 data-no-drag 属性为 "true" 的元素拖拽
        delay: 0, // number 定义鼠标选中列表单元可以开始拖动的延迟时间
        ghostClass: 'sortable-ghost',
        chosenClass: styles['sortable-chosen'],
        dragClass: 'sortable-drag',
        onStart: () => {
          // setLoadingUpload(true);
        },
        onEnd: handleOnEnd,
        onMove(e: any) {
          return e.related.className.indexOf('filtered') === -1;
        },
      });
    }

    return () => {
      if (sortable) {
        sortable.destroy();
      }
    };
  }, [images, sortDargRef]);

  return (
    <div className={styles.upload}>
      <div style={{ marginBottom: label ? 12 : 0 }}>
        {label && <Label>{label}</Label>}
        {images?.length === 0 && disabled && <span style={{ marginLeft: 12 }}>--</span>}
        {!disabled && images.length > 0 && canOcrFlag && (
          <Button
            type="link"
            size="small"
            disabled={loading}
            icon={<span className="iconfont icon-ocr" style={{ marginRight: 4, fontSize: 14 }} />}
            onClick={handleOnStartOcr}
          >
            {!finished ? t('图片智能识别') : t('重新智能识别')}
          </Button>
        )}
      </div>
      {(images?.length > 0 || !disabled) && (
        <div ref={sortDargRef} className={styles['record-img']}>
          {images?.map((url, index) => {
            return (
              <OcrImage
                id={url}
                key={`${url}_${index}`}
                src={url}
                request={false}
                onDelete={(e: any) => handleOnDelete(e, index)}
                onUpdate={(e: any) => handleOnUpdate(index, e)}
                onEdit={onEdit}
                disabled={disabled || loading}
                onPreview={() => {
                  setViewImages(images?.map((item) => ({ src: previewFile(item) })));
                  setViewImagesIndex(index);
                }}
              />
            );
          })}
          {!disabled && !loading && (
            <SingleCompress
              accept={accept}
              fileType={fileType}
              recordId={recordId}
              onUpdate={onUploadSuccess}
              onCanOcr={setCanOcrFlag}
            />
          )}
          {/* {!disabled && (
              <Upload
                accept={accept}
                minDimension={15}
                maxDimension={8192}
                onUploadSuccess={onUploadSuccess}
              />
            )} */}
        </div>
      )}
      {/* 自定义图片-大屏预览 */}
      <ReactViewer
        images={viewImages}
        request={false}
        activeIndex={viewImagesIndex}
        onClose={() => {
          setViewImages([]);
          setViewImagesIndex(0);
        }}
      />
    </div>
  );
};

export default RecordImgUpload;
