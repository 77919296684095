/* eslint-disable indent */
/*
 * @Description: 讲稿演示-快捷预览
 * @Author: likaifeng
 * @Date: 2023-11-30 19:19:50
 * @LastEditTime: 2025-01-06 12:19:55
 * @LastEditors: 李开锋
 */
import CompareImageItem from '@/components/CompareImageItem';
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import {
  DrugRecordCheckbox,
  GeneticTestingCheckbox,
  HospitalDischargeRecordsCheckbox,
  ImageReportCheckbox,
  MainDetailTime,
  MedicalRecordCheckbox,
  OtherInformationCheckbox,
  PathologyReportCheckbox,
  SurgicalRecordCheckbox,
} from '@/pages/speechLecture/hook/config';
import { filterImageType, handleStartDownloadImg } from '@/pages/speechLecture/hook/makeSpeech';
import {
  speechFilterEditAtom,
  speechMainDetailsAtom,
  speechMainDetailTabsAtom,
  speechTabEnumKeysAtom,
} from '@/store/speech';
import { formatDate, transformTumorStage } from '@/utils';
import { inspctionType } from '@/utils/constant';
import { Table } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-快捷预览
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:28:29
 */
const SpeechSubfield: React.FC = () => {
  const specialUrl = ['compareImage', 'correlationCharts'];
  const [speechMainDetail, setSpeechMainDetail] = useRecoilState(speechMainDetailsAtom);
  const speechTabEnumKey = useRecoilValue(speechTabEnumKeysAtom);
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const [speechMainDetailTab, setSpeechMainDetailTab] = useRecoilState(speechMainDetailTabsAtom);
  const tumorMarker = useDict('tumorMarker');
  const { t } = useLocales();

  const [time, setTime] = useState('');

  /**
   * @description: 计算表格的列
   * @author: likaifeng
   * @param {any} data
   * @return {*}
   * @Date: 2023-12-01 10:17:53
   */
  const clacTableColumns = (data: any): any => {
    const arr: any = [];

    if (!data) return arr;

    if (data?.type) {
      arr.push({
        title: '检验项目',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render(text: string, item: any): JSX.Element {
          return <span>{inspctionType[Number(text)]}</span>;
        },
      });
    }
    if (data?.tumorMarkers) {
      arr.push({
        title: '指标名称',
        dataIndex: 'tumorMarkers',
        key: 'tumorMarkers',
        align: 'center',
        render(text: string, item: any): JSX.Element {
          return <span>{item?.type === '0' ? tumorMarker?.[text] : text}</span>;
        },
      });
    }
    if (data?.unit) {
      arr.push({
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        align: 'center',
      });
    }
    if (data?.resultValueItem) {
      arr.push({
        title: '结果',
        dataIndex: 'resultValueItem',
        key: 'resultValueItem',
        align: 'center',
      });
    }
    if (data?.referenceItem) {
      arr.push({
        title: '参考区间',
        dataIndex: 'referenceItem',
        key: 'referenceItem',
        align: 'center',
      });
    }

    return arr.map((item: any) => {
      return {
        ...item,
        title: t(item.title),
      };
    });
  };

  const calcShowImageCard = () => {
    return (
      (speechMainDetailTab === 'medicalRecord' ||
        speechMainDetailTab === 'hospitalDischargeRecords' ||
        speechMainDetailTab === 'pathologyReport' ||
        speechMainDetailTab === 'drugRecord' ||
        speechMainDetailTab === 'imageReport' ||
        speechMainDetailTab === 'geneticTesting' ||
        speechMainDetailTab === 'surgicalRecord' ||
        speechMainDetailTab === 'inspectionReport' ||
        speechMainDetailTab === 'otherInformation') &&
      (!!speechMainDetail?.imagePath?.length || !!speechMainDetail?.reportImage?.length)
    );
  };

  /**
   * @description: 判断是否隐藏卡片
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 11:53:47
   */
  const calcDisplay = () => {
    let showKey: any[] = [];
    // 入院记录
    if (speechMainDetailTab === 'medicalRecord') {
      showKey = MedicalRecordCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'imagePath',
      ).map((el) => el.key);
    }
    // 出院记录
    if (speechMainDetailTab === 'hospitalDischargeRecords') {
      showKey = HospitalDischargeRecordsCheckbox.filter(
        (el) => el.key !== 'dischargeTime' && el.key !== 'imagePath',
      ).map((el) => el.key);
    }
    // 影像报告
    if (speechMainDetailTab === 'imageReport') {
      showKey = ImageReportCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'reportImage' && el.key !== 'zhWedcmTask',
      ).map((el) => el.key);
    }
    // 手术信息
    if (speechMainDetailTab === 'surgicalRecord') {
      showKey = SurgicalRecordCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'reportImage',
      ).map((el) => el.key);
    }
    // 病理报告
    if (speechMainDetailTab === 'pathologyReport') {
      showKey = PathologyReportCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'imagePath',
      ).map((el) => el.key);
    }
    // 用药记录
    if (speechMainDetailTab === 'drugRecord') {
      showKey = DrugRecordCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'imagePath',
      ).map((el) => el.key);
    }
    // 基因检测
    if (speechMainDetailTab === 'geneticTesting') {
      showKey = GeneticTestingCheckbox.filter(
        (el) => el.key !== 'recordTime' && el.key !== 'imagePath',
      ).map((el) => el.key);
    }
    // 参考文献
    if (speechMainDetailTab === 'otherInformation') {
      showKey = OtherInformationCheckbox.filter(
        (el) => el.key !== 'informationDate' && el.key !== 'reportImage',
      ).map((el) => el.key);
    }

    const fils = showKey.filter((el) => speechMainDetail[el]);
    return !!fils.length;
  };

  useEffect(() => {
    if (specialUrl.includes(speechMainDetailTab)) return;

    if (speechMainDetailTab === 'hospitalDischargeRecords') {
      setTime(formatDate(speechMainDetail?.dischargeTime));
    } else if (speechMainDetailTab === 'otherInformation') {
      setTime(formatDate(speechMainDetail?.informationDate));
    } else {
      setTime(formatDate(speechMainDetail?.recordTime));
    }
  }, [speechMainDetail, speechMainDetailTab]);

  useEffect(() => {
    if (speechFilterEditFlag) {
      setSpeechMainDetail(null);
      setSpeechMainDetailTab(null);
    }
  }, [speechFilterEditFlag]);

  return speechMainDetail && !speechFilterEditFlag ? (
    <SpeechCard title="详细信息" style={{ marginBottom: 0, marginLeft: 10 }} close>
      <div className={classNames(styles['speech-subfield'], styles.subfield)}>
        {/* 时间处理 */}
        <SpeechStepCard margin="0 0 16px 0" background="#E6F1FC">
          <SpeechField
            color
            title={speechTabEnumKey[speechMainDetailTab || '']}
            border={false}
            description={
              specialUrl.includes(speechMainDetailTab)
                ? speechMainDetail?.name
                : `${MainDetailTime[speechMainDetailTab || '']}：${time}`
            }
          />
        </SpeechStepCard>
        {/* 图片处理 */}
        <SpeechStepCard
          disabled={calcShowImageCard()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 4px 16px"
        >
          {/* 入院记录 出院记录 影像报告 */}
          {speechMainDetail?.imagePath && (
            <SpeechField
              title="报告图片"
              color
              border={false}
              type="IMAGE"
              imagesSource={handleStartDownloadImg(speechMainDetail.imagePath)}
            />
          )}
          {/* 手术信息 影像报告 */}
          {speechMainDetail?.reportImage && (
            <SpeechField
              title={speechMainDetailTab === 'imageReport' ? '报告图片/视频' : '报告图片'}
              color
              border={false}
              type="IMAGE"
              imagesSource={handleStartDownloadImg(speechMainDetail.reportImage)}
            />
          )}
        </SpeechStepCard>
        {/* 入院记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'medicalRecord' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {MedicalRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== MedicalRecordCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 出院记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'hospitalDischargeRecords' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {HospitalDischargeRecordsCheckbox?.map((item: any, index: number) =>
            item.key !== 'dischargeTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== HospitalDischargeRecordsCheckbox.length - 1}
                description={
                  item.key !== 'admitTime'
                    ? speechMainDetail[item.key]
                    : formatDate(speechMainDetail[item.key])
                }
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 影像报告 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'imageReport' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {ImageReportCheckbox?.map((item: any, index: number) => {
            if (
              item.key !== 'recordTime' &&
              item.key !== 'reportImage' &&
              item.key !== 'zhWedcmTask' &&
              item.key !== 'dicomQrcodeUrl'
            ) {
              return (
                <SpeechField
                  key={item.key}
                  color
                  title={item.value}
                  border={index !== ImageReportCheckbox.length - 1}
                  description={
                    item.key !== 'imagesType'
                      ? speechMainDetail[item.key]
                      : filterImageType(speechMainDetail[item.key])
                  }
                />
              );
            }
            if (item.key === 'zhWedcmTask') {
              return (
                <SpeechField
                  key={item.key}
                  color
                  type="BUTTON"
                  dicomId={speechMainDetail.id}
                  zhWedcmTask={speechMainDetail.zhWedcmTask}
                  title={item.value}
                  border={index !== ImageReportCheckbox.length - 1}
                  description={speechMainDetail.zhWedcmTask ? '查看影像' : '-'}
                />
              );
            }
            if (item.key === 'dicomQrcodeUrl') {
              return (
                <SpeechField
                  border
                  color
                  title="DICOM预览链接"
                  type="LINK"
                  description={speechMainDetail.dicomQrcodeUrl}
                />
              );
            }
            return null;
          })}
        </SpeechStepCard>
        {/* 手术信息 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'surgicalRecord' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {SurgicalRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'reportImage' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== SurgicalRecordCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 病理报告 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'pathologyReport' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {PathologyReportCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== PathologyReportCheckbox.length - 1}
                description={
                  item.key !== 'tumorStaging'
                    ? speechMainDetail[item.key]
                    : transformTumorStage(speechMainDetail[item.key])
                }
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 检验报告 */}
        <SpeechStepCard
          disabled={
            speechMainDetailTab === 'inspectionReport' &&
            !!speechMainDetail?.zhInspectionItems?.length
          }
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          <SpeechField
            disabled={!speechMainDetail.zhInspectionItems}
            type="OTHER"
            border
            color
            title="检验项目"
          >
            {speechMainDetail?.zhInspectionItems?.length ? (
              <Table
                rowKey="id"
                dataSource={speechMainDetail?.zhInspectionItems}
                columns={clacTableColumns(speechMainDetail?.zhInspectionItems?.[0])}
                pagination={false}
              />
            ) : null}
          </SpeechField>
        </SpeechStepCard>
        <SpeechStepCard
          disabled={speechMainDetailTab === 'inspectionReport' && !!speechMainDetail.description}
          background="#E6F1FC"
          margin="0 0 16px 0"
        >
          <SpeechField
            title="描述"
            color
            border={false}
            description={speechMainDetail.description}
          />
        </SpeechStepCard>
        {/* 用药记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'drugRecord' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {DrugRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== DrugRecordCheckbox.length - 1}
                description={
                  item.key === 'useTime'
                    ? `${formatDate(speechMainDetail.startMedicationTime)}至${formatDate(
                        speechMainDetail.endMedicationTime,
                      )}`
                    : speechMainDetail[item.key]
                }
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 基因检测 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'geneticTesting' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {GeneticTestingCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== GeneticTestingCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 参考文献 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'otherInformation' && calcDisplay()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {OtherInformationCheckbox?.map((item: any, index: number) =>
            item.key !== 'informationDate' && item.key !== 'reportImage' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== OtherInformationCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 对比图片 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'compareImage'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          <CompareImageItem
            title={speechMainDetail.name}
            images={[speechMainDetail.previousImage, speechMainDetail.nextImage]}
          />
          <SpeechField
            style={{ paddingTop: 16 }}
            title="图片说明"
            description={speechMainDetail.instruction}
          />
        </SpeechStepCard>
        {/* 趋势图 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'correlationCharts'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          <SpeechField
            compareTitle="报告图片"
            border={false}
            style={{ marginBottom: speechMainDetail?.instruction ? 4 : 0 }}
            type="IMAGE"
            imagesSource={speechMainDetail?.thumbnail}
          />
          <SpeechField title="图片说明" description={speechMainDetail?.instruction} />
        </SpeechStepCard>
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechSubfield;
