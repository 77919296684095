/* eslint-disable indent */
import { useLocales } from '@/Locales';
import { PatientInfoAtom } from '@/pages/patient/detail';
import ChartsTab from '@/pages/patient/detail/components/ChartsTab';
import CompareImageTab from '@/pages/patient/detail/components/CompareImageTab';
import DrugRecordsTab from '@/pages/patient/detail/components/DrugRecordsTab';
import GeneticTestingsTab from '@/pages/patient/detail/components/GeneticTestingsTab';
import HospitalDischargeRecordsTab from '@/pages/patient/detail/components/HospitalDischargeRecordsTab';
import ImageReportsTab from '@/pages/patient/detail/components/ImageReportsTab';
import InspectionReportsTab from '@/pages/patient/detail/components/InspectionReportsTab';
import MdtIssuesTab from '@/pages/patient/detail/components/MdtIssuesTab';
import MedicalRecordsItem from '@/pages/patient/detail/components/MedicalRecordsTab';
import OtherTab from '@/pages/patient/detail/components/OtherTab';
import PathologyRecordsTab from '@/pages/patient/detail/components/PathologyRecordsTab';
import PatientInfo from '@/pages/patient/detail/components/PatientInfo';
import TabItem from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import SurgicalRecordsTab from '@/pages/patient/detail/components/SurgicalRecordsTab';
import {
  deleteDischarge,
  deletePatientDrugRecords,
  deletePatientGeneticTesting,
  deletePatientImages,
  deletePatientInspection,
  deletePatientMedical,
  deletePatientOther,
  deletePatientPathology,
  deletePatientSurgical,
} from '@/services/patient';
import { Tabs, message } from '@sinohealth/butterfly-ui-components';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';

const PatientTabs = (props: any) => {
  const { showBaseInfo, style } = props;
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [selectedTab, setSelectedTab] = useState(showBaseInfo ? 'baseInfo' : 'MedicalRecords');
  const { t, locales } = useLocales();

  const items = useMemo(() => {
    const renderChildren = (config: any) => {
      return (
        <div style={{ minHeight: '360px', display: 'flex', gap: '12px' }}>
          {config.children ? (
            config.children
          ) : (
            <TabItem
              fieldKey={config.key}
              label={config.label}
              renderChildren={config.renderChildren}
              onDelete={config.onDelete}
            />
          )}
        </div>
      );
    };
    const baseInfoTab: any = showBaseInfo
      ? [
          {
            key: 'baseInfo',
            label: t('基本信息'),
            children: <PatientInfo defaultOpenSummary />,
          },
        ]
      : [];
    return [
      ...baseInfoTab,
      {
        key: 'MedicalRecords',
        label: t('入院记录'),
        renderChildren(key: string, data: any) {
          return <MedicalRecordsItem key={key} data={data} />;
        },
        onDelete(item: any) {
          return deletePatientMedical({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'hospitalDischargeRecords',
        label: t('出院记录'),
        renderChildren(key: string, data: any) {
          return <HospitalDischargeRecordsTab key={key} data={data} />;
        },
        onDelete(item: any) {
          return deleteDischarge({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhImageReports',
        label: t('影像报告'),
        renderChildren(key: string, data: any) {
          return <ImageReportsTab key={key} data={data} />;
        },
        onDelete(item: any) {
          return deletePatientImages({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhSurgicalRecords',
        label: t('手术信息'),
        renderChildren(key: string, data: any) {
          return <SurgicalRecordsTab key={key} data={data} />;
        },
        onDelete(item: any) {
          return deletePatientSurgical({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhPathologyReports',
        label: t('病理报告'),
        renderChildren(key: string, data: any) {
          return <PathologyRecordsTab key={key} data={data} />;
        },
        onDelete(item: any) {
          return deletePatientPathology({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhInspectionReports',
        label: t('检验报告'),
        renderChildren(key: string, data: any) {
          return <InspectionReportsTab key={key} data={data} />;
        },
        onDelete(item: any) {
          return deletePatientInspection({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhGeneticTestings',
        label: t('基因检测'),
        renderChildren(key: string, data: any) {
          return <GeneticTestingsTab key={key} data={data} />;
        },
        onDelete(item: any): any {
          return deletePatientGeneticTesting({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhDrugRecords',
        label: t('用药记录'),
        renderChildren(key: string, data: any) {
          return <DrugRecordsTab key={key} data={data} />;
        },
        onDelete(item: any): any {
          return deletePatientDrugRecords({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
      {
        key: 'zhPatientMdtIssues',
        label: t('MDT议题'),
        children: <MdtIssuesTab />,
      },
      {
        key: 'comparisoCharts',
        label: t('对比图片'),
        children: <CompareImageTab />,
      },
      {
        key: 'correlationCharts',
        label: t('趋势图'),
        children: <ChartsTab />,
      },
      {
        key: 'otherInformations',
        label: t('参考文献'),
        renderChildren(key: string, data: any) {
          return <OtherTab key={key} data={data} />;
        },
        onDelete(item: any): any {
          return deletePatientOther({
            id: item.data.id,
          }).then(() => {
            message.success(t('删除成功'));
            patientInfo.update();
          });
        },
      },
    ].map((item) => {
      return {
        ...item,
        children: renderChildren(item),
      };
    });
  }, [patientInfo, locales]);
  return (
    <div className={styles.tabs} style={style}>
      <Tabs activeKey={selectedTab} onChange={setSelectedTab} items={items} />
    </div>
  );
};

export default PatientTabs;
