/* eslint-disable no-shadow */
import ImageOcrParsing from '@/pages/patient/detail/components/ImageOcrParsing';
import { OcrContext } from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import { useOcrContextValue } from '@/pages/patient/hooks/useOcr';
import { speechFilterEditAtom, speechUpdateTimeAtom } from '@/store/speech';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// 定义通用的 Props 类型
type Props<T> = T & {
  // 添加任何通用的 props，例如 className
  [key: string]: any;
};

export enum ORIGIN_TYPE {
  baseInfo = 'baseInfo',
  medicalRecord = 'medicalRecord',
  hospitalDischargeRecords = 'hospitalDischargeRecords',
  imageReport = 'imageReport',
  surgicalRecord = 'surgicalRecord',
  pathologyReport = 'pathologyReport',
  inspectionReport = 'inspectionReport',
  geneticTesting = 'geneticTesting',
  drugRecord = 'drugRecord',
  compareImage = 'compareImage',
  correlationCharts = 'correlationCharts',
  otherInformation = 'otherInformation',
}

/**
 * @description: 高阶组件-整合OCR识别功能
 * @author: KaifengLi
 * @param {} WrappedComponent
 * @param {} type //
 * @param {} origin // 数据源
 * @version: v2.20.0
 * @Date: 2024-08-08 17:37:51
 */
function HocForm<T>(WrappedComponent: React.ComponentType<T>) {
  const WrappedWithOcr: React.FC<Props<T>> = (props) => {
    const [params] = useSearchParams();
    const patientId = params.getAll('patientId')[0];
    // const [ocrConfig, setOcrConfig] = useState<any>({});
    // const [loading, setLoading] = useState(false);
    // const [ocrDetail, setOcrDetail] = useState<any>([]);
    // const [reportText, setReportText] = useState(''); // 识别的文本
    // const [finished, setFinished] = useState(false); // 是否识别完成
    // const [controllerRef, setControllerRef] = useState<AbortController | null>(null);
    const [firstRender, setFirstRender] = useState(true);

    const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
    const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);

    const { ocrContextValue, ocrConfig } = useOcrContextValue();

    // const ocrContextValue = useMemo(() => {
    //   return {
    //     ocrConfig,
    //     setOcrConfig,
    //     ocrDetail,
    //     setOcrDetail,
    //     loading,
    //     setLoading,
    //     controllerRef,
    //     setControllerRef,
    //     reportText,
    //     setReportText,
    //     finished,
    //     setFinished,
    //   };
    // }, [
    //   ocrConfig,
    //   setOcrConfig,
    //   loading,
    //   setLoading,
    //   ocrDetail,
    //   setOcrDetail,
    //   controllerRef,
    //   setControllerRef,
    //   reportText,
    //   setReportText,
    //   finished,
    //   setFinished,
    // ]);

    const [hasChange, setHasChange] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null); // 新增 ref 用于引用 DOM 节点
    const [height, setHeight] = useState<number>(0); // 新增状态用于保存高度
    useEffect(() => {
      const currentContainer: any = containerRef.current;
      if (!currentContainer) return;
      if (props?.type) {
        const elements: any = containerRef.current?.getElementsByClassName('card-time-step');

        // 获取所有匹配元素的高度总和
        let totalHeight = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < elements.length; i++) {
          totalHeight += elements[i].offsetHeight;
        }
        // console.log('Total height of elements with class "step-card":', totalHeight);
        setHeight(totalHeight - 90);
      } else {
        setHeight(currentContainer.offsetHeight - 114); // 设置高度
      }
    }, [containerRef, ocrConfig, hasChange]); // 当 ref 更新时重新计算高度

    useMemo(() => {
      if (speechFilterEditFlag) {
        const timer = setTimeout(() => {
          setFirstRender(false);
          clearTimeout(timer);
        }, 3000);
      } else {
        setFirstRender(true);
      }
    }, [speechFilterEditFlag]);

    return (
      <OcrContext.Provider value={ocrContextValue}>
        <div ref={containerRef}>
          <WrappedComponent
            {...props}
            patientId={patientId}
            ocrContent={<ImageOcrParsing ocrConfig={ocrConfig} />}
            firstRender={firstRender}
            onAdd={() => setHasChange(new Date().getTime())}
            speechUpdateTime={speechUpdateTime}
          />
        </div>
      </OcrContext.Provider>
    );
  };

  return WrappedWithOcr;
}

export default HocForm;
