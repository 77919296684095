import { useLocales } from '@/Locales';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  blueType,
  greenType,
  greenTypeEnum,
  purpleType,
  recordTimeFormate,
  redType,
} from '../Horizontal';
import styles from './index.less';
import TriggerElement from '../TriggerElement';
import EditAndDelElement from '../EditAndDelElement';
import PreviewRelaElement from '../PreviewRelaElement';

export interface VerticalProps {
  edit?: boolean;
  detail?: any;
  onRela?: (id: string, ty: string) => void; // 查看关联记录
  onEdit?: () => void; // 编辑
  onDelete?: (val?: any) => void; // 删除
}

/**
 * @description: 时间轴组件-新版设计稿-纵向时间轴
 * @author: KaifengLi
 * @version: v2.28.0
 * @Date: 2024-12-24 11:26:06
 */
const Vertical: React.FC<VerticalProps> = (props) => {
  const { edit, detail, onRela, onEdit, onDelete } = props;

  const lastElementRef = useRef<HTMLDivElement>(null);
  const [lastElementWidth, setLastElementWidth] = useState(0);
  const [showLine, setShowLine] = useState(true);
  const { t } = useLocales();

  const handleCloseLine = () => {
    setShowLine(!showLine);
  };

  useEffect(() => {
    if (lastElementRef.current && showLine) {
      const rect = lastElementRef.current.getBoundingClientRect();
      setLastElementWidth(rect?.width);
    }
  }, [showLine, detail]);

  return (
    <div
      className={styles.vertical}
      style={
        { paddingBottom: showLine ? 20 : 0, '--lastElementWidth': `${lastElementWidth}px` } as any
      }
    >
      <div className={classNames(styles.month, !showLine && styles['month-line'])}>
        {detail?.yearMonth}
      </div>
      <div className={styles.container}>
        {/* {TriggerElement(showLine, handleCloseLine, styles.close, 'VERTICAL')} */}
        <TriggerElement
          value={showLine}
          direction="VERTICAL"
          onChange={handleCloseLine}
          className={styles.close}
        />
        {detail?.data?.map((item: any, index: number) => (
          <React.Fragment key={item?.id}>
            {redType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.red)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('治疗信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {greenType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.green)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t(greenTypeEnum[item?.nodeType])}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {blueType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.blue)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('影像信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {purpleType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.purple)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('其他信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Vertical;
