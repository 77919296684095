import React, { useRef } from 'react';
import { Divider, Popconfirm, Space } from '@sinohealth/butterfly-ui-components';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import GenesForm from '../../../GenesForm';
import ImageForm from '../../../ImageForm';
import InspectForm from '../../../InspectForm';
import OtherForm from '../../../OtherForm';
import PathologyForm from '../../../PathologyForm';
import TreatmentForm from '../../../TreatmentForm';
import { blueType, greenType, purpleType, redType } from '../Horizontal';

interface EditAndDelElementProps {
  item: any;
  onEdit?: () => void;
  onDelete?: (val?: any) => void;
  dateStr?: string;
  edit?: boolean;
}

const EditAndDelElement: React.FC<EditAndDelElementProps> = (props) => {
  const { item, onEdit, onDelete, dateStr, edit } = props;
  const imageFormRef = useRef<any>(null);
  const inspectFormRef = useRef<any>(null);
  const pathologyFormRef = useRef<any>(null);
  const genesFormRef = useRef<any>(null);
  const treatmentFormRef = useRef<any>(null);
  const otherFormFormRef = useRef<any>(null);

  const handleEditAxis = () => {
    if (item?.nodeType === 'IMAGE_REPORT') {
      imageFormRef.current?.open(item);
    }
    if (item?.nodeType === 'INSPECTION_REPORT') {
      inspectFormRef.current?.open(item);
    }
    if (item?.nodeType === 'PATHOLOGY_REPORT') {
      pathologyFormRef.current?.open({
        ...item,
        diagnosisDescription: item?.diagnosisDescription
          ? JSON.parse(item?.diagnosisDescription)
          : {},
      });
    }
    if (item?.nodeType === 'GENETIC_TESTING') {
      genesFormRef.current?.open(item);
    }
    const type = [
      'TREATMENT_RADIOTHERAPY',
      'TREATMENT_OTHER_INFORMATION',
      'SURGICAL_RECORD',
      'DRUG_RECORD',
    ];
    if (type.includes(item?.nodeType)) {
      treatmentFormRef.current?.open(item);
    }
    const otherType = ['DIAGNOSIS', 'MDT_CONCLUSION', 'OTHER_INFORMATION'];
    if (otherType.includes(item?.nodeType)) {
      otherFormFormRef.current?.open(item);
    }
  };

  const handleDeleteAxis = () => {
    if (greenType.includes(item?.nodeType)) {
      // 检验 病理 基因
      onDelete?.({
        timeLineList: [
          {
            dateStr,
            detectionRecords: [
              {
                ...item,
                diagnosisDescription: item?.diagnosisDescription
                  ? JSON.stringify(item?.diagnosisDescription)
                  : undefined,
                deleted: 1,
              },
            ],
          },
        ],
      });
    }
    if (blueType.includes(item?.nodeType)) {
      // 影像
      onDelete?.({
        timeLineList: [
          {
            dateStr,
            imageReportRecords: [{ ...item, deleted: 1 }],
          },
        ],
      });
    }
    if (redType.includes(item?.nodeType)) {
      // 治疗
      onDelete?.({
        timeLineList: [
          {
            dateStr,
            treatmentRecords: [{ ...item, deleted: 1 }],
          },
        ],
      });
    }
    if (purpleType.includes(item?.nodeType)) {
      // 其他
      onDelete?.({
        timeLineList: [
          {
            dateStr,
            diagnosisRecords: [{ ...item, deleted: 1 }],
          },
        ],
      });
    }
  };

  return (
    <>
      {edit && (
        <Space size={0} split={<Divider type="vertical" style={{ borderColor: '#ffffff' }} />}>
          <EditOutlined style={{ cursor: 'pointer' }} onClick={handleEditAxis} />
          <Popconfirm
            title="是否确认删除该项"
            onConfirm={handleDeleteAxis}
            okText="确认"
            cancelText="取消"
          >
            <DeleteOutlined style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </Space>
      )}
      <ImageForm edit ref={imageFormRef} onSubmit={onEdit} />
      <InspectForm edit ref={inspectFormRef} onSubmit={onEdit} />
      <PathologyForm edit ref={pathologyFormRef} onSubmit={onEdit} />
      <GenesForm edit ref={genesFormRef} onSubmit={onEdit} />
      <TreatmentForm edit ref={treatmentFormRef} onSubmit={onEdit} />
      <OtherForm edit ref={otherFormFormRef} onSubmit={onEdit} />
    </>
  );
};

export default EditAndDelElement;
