import {
  DeleteOutlined,
  DownCircleFilled,
  EditOutlined,
  EyeOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  UpCircleFilled,
} from '@ant-design/icons';
import React from 'react';
import { Tooltip } from '@sinohealth/butterfly-ui-components';

interface TriggerElementProps {
  value: boolean;
  onChange: () => void;
  className?: string | undefined;
  direction?: 'VERTICAL' | 'HORIZONTAL';
}

const TriggerElement: React.FC<TriggerElementProps> = (props) => {
  const { value, onChange, className, direction } = props;

  const getIcon = () => {
    if (!value) {
      return direction === 'HORIZONTAL' ? (
        <DownCircleFilled style={{ fontSize: 18, color: '#4288ff', cursor: 'pointer' }} />
      ) : (
        <RightCircleFilled style={{ fontSize: 18, color: '#4288ff', cursor: 'pointer' }} />
      );
    }
    return direction === 'HORIZONTAL' ? (
      <UpCircleFilled style={{ fontSize: 18, color: '#4288ff' }} />
    ) : (
      <LeftCircleFilled style={{ fontSize: 18, color: '#4288ff' }} />
    );
  };

  return (
    <Tooltip
      placement={direction === 'HORIZONTAL' ? 'right' : 'top'}
      title={!value ? '展开' : '收起'}
      color="#FFFFFF"
      overlayInnerStyle={{ color: '#525252' }}
    >
      <div className={className} onClick={onChange}>
        {getIcon()}
      </div>
    </Tooltip>
  );
};

export default TriggerElement;
