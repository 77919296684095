/* eslint-disable no-param-reassign */
import { useLocales } from '@/Locales';
import { uploadFile } from '@/services/common';
import { getOutputFileName } from '@/utils/picControler/functions';
import { convert } from '@/utils/picControler/handler';
import { CompressFilesProps, ImageItem } from '@/utils/picControler/ImageBase';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Space } from '@sinohealth/butterfly-ui-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useTransform, { createMessageData } from '../../hook/useTransform';
import { DefaultCompressOption } from '../../store/smallerAtoms';
import useHomeState from '../../store/useSmaller';
import ImageInput from '../ImageInput';
import styles from './index.less';

interface PicSmallerUploadProps {
  auto?: boolean; // 是否自动上传
  disabled?: boolean; // 是否禁用
  loading?: boolean; // 外置上传loading
  accept?: string; // 接受文件类型
  uploadTip?: string; // 上传提示
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  onChange?: (urls: string[], file: CompressFilesProps[], fileLeng?: number) => void;
}

/**
 * @description: 图片压缩上传组件
 * @author: KaifengLi
 * @version: v2.25.0
 * @Date: 2024-11-01 14:17:32
 */
const PicSmallerUpload: React.FC<PicSmallerUploadProps> = (props) => {
  const {
    auto = true,
    loading = false,
    disabled = false,
    accept,
    uploadTip = '上传中',
    multiple = false,
    maxFiles,
    maxSize,
    onChange,
  } = props;
  const { t } = useLocales();
  const fileRef = useRef<HTMLInputElement>(null);
  const { option } = useHomeState();
  const { compressMessageImage } = useTransform();

  const [uploading, setUploading] = useState(false);
  const [videoUploadFiles, setVideoUploadFiles] = useState<Array<File>>([]);
  const [videoUpload, setVideoUpload] = useState(false);

  const handleUploadVideo = (videoFiles: Array<File>) => {
    // setUploading(true);
    setVideoUpload(true);
    if (!videoFiles.length) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
    setVideoUploadFiles((prev) => [...prev, ...videoFiles]);

    const uploadPromises = videoFiles.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      return uploadFile(formData).then((res: any) => res);
    });

    return new Promise((resolve, reject) => {
      Promise.all(uploadPromises)
        .then((results) => {
          // console.log(results);
          // onChange?.(results, [], 'video');
          // setVideoUrls(results);)
          setVideoUploadFiles([]);
          resolve(results);
        })
        .catch((error) => {
          reject(error);
          console.error('Error uploading files:', error);
        })
        .finally(() => {
          setUploading(false);
          setVideoUpload(false);
        });
    });
  };

  const handleImageChange = useCallback(
    async (promise: Promise<ImageItem>[], videoFile?: Array<File>) => {
      const promiseResults = await Promise.all(promise);
      const uploadPromises: Promise<any>[] = [];
      const compressFiles: CompressFilesProps[] = [];

      // 视频文件直接上传，暂只有在单个时候支持
      const videoUrls: any = await handleUploadVideo(videoFile || []);

      await Promise.all(
        promiseResults.map(async (item: ImageItem) => {
          const msg = createMessageData(item, option);
          if (item.blob.type !== 'image/bmp') {
            setUploading(true);
            const compressOutput = await convert(msg, 'compress');
            // console.log('压缩结果', compressOutput);
            if (compressOutput && compressOutput?.compress?.blob) {
              const list = compressMessageImage(item, compressOutput);
              if (list) {
                const fileName = getOutputFileName(list, DefaultCompressOption);
                const file = new File([compressOutput.compress.blob], fileName, {
                  type: compressOutput.compress.blob.type,
                });
                compressFiles.push({
                  file,
                  compress: compressOutput.compress,
                });
                if (auto) {
                  const formDate = new FormData();
                  formDate.append('file', file);
                  uploadPromises.push(uploadFile(formDate));
                  // console.log(`文件 ${fileName} 的上传 Promise 已添加`);
                }
              }
            }
          } else {
            const file = new File([msg?.info.blob], msg?.info.name, {
              type: msg?.info.blob.type,
            });
            compressFiles.push({
              file,
              compress: {
                width: msg?.info.width,
                height: msg?.info.height,
                blob: msg?.info.blob,
                src: URL.createObjectURL(msg?.info.blob),
              },
            });
          }
        }),
      );

      // 等待所有上传操作完成
      try {
        const res = await Promise.all(uploadPromises);
        console.log('所有文件上传完成', res, compressFiles);
        onChange?.(videoUrls, compressFiles, promise.length + (videoUrls?.length || 0));
      } catch (error) {
        console.error('上传过程中发生错误:', error);
      } finally {
        setUploading(false);
      }
    },
    [auto, onChange, option, compressMessageImage],
  );

  useEffect(() => {
    console.log('loading', loading);
    setUploading(loading);
  }, [loading]);

  return (
    <>
      {videoUploadFiles.length > 0 && (
        <div className={styles['pic-smaller-upload']}>
          {videoUpload && (
            <Space direction="vertical">
              <LoadingOutlined style={{ fontSize: 18 }} />
              <div>{t('视频上传中')}...</div>
            </Space>
          )}
        </div>
      )}
      <div>
        <div
          className={styles['pic-smaller-upload']}
          onClick={() => {
            if (uploading || disabled) {
              message.error(t('有任务处理中，请稍后'));
              return;
            }
            fileRef.current?.click();
          }}
        >
          {uploading && (
            <Space direction="vertical">
              <LoadingOutlined style={{ fontSize: 18 }} />
              <div>{t(uploadTip)}...</div>
            </Space>
          )}
          {!uploading && (
            <Space direction="vertical">
              <PlusOutlined />
              <div>{t('上传图片')}</div>
            </Space>
          )}
        </div>
        <ImageInput
          ref={fileRef}
          accept={accept}
          maxFiles={maxFiles}
          maxSize={maxSize}
          multiple={multiple}
          onChange={handleImageChange}
        />
      </div>
    </>
  );
};

export default PicSmallerUpload;
