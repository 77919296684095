import { useLocales } from '@/Locales';
import { FormItem } from '@/components/BaseForm';
import { useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AssociatedDetails from '../AssociatedDetails';
import FormLabel from '../FormLabel';
import { EditFormProps } from '../ImageForm';

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-治疗信息-FORM
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-10 15:54:22
 */
const TreatmentForm = forwardRef((props: EditFormProps, ref: any) => {
  const { t } = useLocales();

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [nodeType, setNodeType] = useState<string>('');
  const { patientId, setTimelineParams, setFormParams, formParams, saveSuccess } =
    useTimeLineSave();

  useImperativeHandle(ref, () => ({
    open: (val: any) => {
      console.log(val);
      setOpen(true);
      form.setFieldsValue({
        ...val,
        recordTime: val.recordTime ? dayjs(val.recordTime) : null,
      });
      setNodeType(val.nodeType);
      setFormParams(val);
    },
  }));

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      setTimelineParams({
        patientId,
        timeLineList: [
          {
            dateStr: formValue.recordTime.format('YYYY-MM'),
            treatmentRecords: [
              {
                ...formParams,
                ...formValue,
                recordTime: formValue.recordTime.format('YYYY-MM-DD'),
              },
            ],
          },
        ],
      });
    });
  };

  const handleOnChangeNoteType = (value: any) => {
    form.setFieldValue('nodeType', value);
    setNodeType(value);
  };

  useEffect(() => {
    if (saveSuccess) {
      props?.onSubmit?.();
      setOpen(false);
    }
  }, [saveSuccess]);

  return (
    <>
      {props?.button && (
        <Button
          style={{
            color: '#FF5C5C',
            borderColor: '#FF5C5C',
            background: '#FFEEEE',
            display: 'flex',
            alignItems: 'center',
          }}
          ghost
          icon={<span className="iconfont icon-a-5" style={{ marginRight: 6 }} />}
          onClick={() => {
            if (props?.edit) {
              setOpen(true);
              setFormParams({});
            }
          }}
        >
          治疗信息
        </Button>
      )}
      <Modal
        title={`${formParams?.id ? t('编辑') : t('新增')} ${t('治疗信息')}`}
        open={open}
        maskClosable={false}
        style={{ top: 20 }}
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        afterClose={() => {
          form.resetFields();
          setFormParams({});
          setNodeType('');
        }}
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          colon={false}
          labelCol={{ span: 12 }}
          autoComplete="off"
        >
          <FormItem name="id" hidden>
            <Input />
          </FormItem>
          <Form.Item
            name="recordTime"
            rules={[{ required: true, message: t('请选择') }]}
            label={<FormLabel label="治疗日期" />}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <FormItem
            label={<FormLabel label="治疗类型" />}
            name="nodeType"
            dictKey="nodeTypeSelect"
            rules={[{ required: true, message: t('请选择') }]}
          >
            <Select
              disabled={formParams?.id !== undefined}
              onChange={handleOnChangeNoteType}
              placeholder={t('请选择')}
            />
          </FormItem>
          {nodeType === 'SURGICAL_RECORD' && (
            <FormItem
              label={<FormLabel label="治疗方案描述" />}
              name="surgeryName"
              rules={[{ required: true, message: t('请输入') }]}
            >
              <Input.TextArea
                maxLength={100}
                showCount
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder={t('请输入')}
              />
            </FormItem>
          )}
          {nodeType === 'DRUG_RECORD' && (
            <FormItem
              label={<FormLabel label="用药方案描述" />}
              name="drugPlan"
              rules={[{ required: true, message: t('请输入') }]}
            >
              <Input.TextArea
                maxLength={200}
                showCount
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder={t('请输入')}
              />
            </FormItem>
          )}
          {nodeType === 'TREATMENT_RADIOTHERAPY' && (
            <FormItem
              label={<FormLabel label="放疗方案描述" />}
              name="description"
              rules={[{ required: true, message: t('请输入') }]}
            >
              <Input.TextArea
                maxLength={200}
                showCount
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder={t('请输入')}
              />
            </FormItem>
          )}
          {nodeType === 'TREATMENT_OTHER_INFORMATION' && (
            <FormItem
              label={<FormLabel label="其他方案描述" />}
              name="description"
              rules={[{ required: true, message: t('请输入') }]}
            >
              <Input.TextArea
                maxLength={200}
                showCount
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder={t('请输入')}
              />
            </FormItem>
          )}
          <FormItem label={<FormLabel label="关联档案详情" tooltip />} name="refContent">
            <AssociatedDetails
              refContent={formParams?.refContent}
              refId={formParams?.refId}
              refType={formParams?.refType}
              onSubmit={(id, type, time) => {
                setFormParams({
                  ...formParams,
                  refId: id || undefined,
                  refType: type || undefined,
                  refContent: time || undefined,
                });
                form.setFieldsValue({ refContent: time });
              }}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
});

export default TreatmentForm;
