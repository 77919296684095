/* eslint-disable react/no-array-index-key */
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import { useFieldToLabel } from '@/pages/patient/hooks/useOcr';
import { useSseSocket } from '@/pages/patient/hooks/useSseSocket';
import { copyText } from '@/utils';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Empty, message, Modal, Spin, theme } from '@sinohealth/butterfly-ui-components';
import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { OcrContext } from '../PatientTabs/components/TabItem';
import styles from './index.less';

const { confirm } = Modal;

interface ImageOcrParsingProps {
  ocrConfig: any;
  height?: number;
}

export const notCopy = [
  'recordTime',
  'endMedicationTime',
  'startMedicationTime',
  'dischargeTime',
  'informationDate',
  'admitTime',
];
const whiteField = ['bizId', 'imagePath', 'content'];

const { useToken } = theme;

/**
 * @description:图片识别内容
 * @author: KaifengLi
 * @version: v2.27.1
 * @Date: 2024-12-09 16:07:10
 */
const ImageOcrParsing = forwardRef((props: ImageOcrParsingProps, ref: any) => {
  const { ocrConfig, height } = props;
  const { token } = useToken();
  const {
    setOcrConfig,
    loading,
    setLoading,
    controllerRef,
    setControllerRef,
    ocrDetail,
    setOcrDetail,
  } = useContext(OcrContext);
  const { t } = useLocales();
  const dict = useDict();

  const {
    setSseParams,
    reportText,
    setReportText,
    closeSSE,
    jsonLoading,
    setJsonLoading,
    textLoading,
    error,
    connectLoading,
  } = useSseSocket();
  const fields = useFieldToLabel(ocrConfig?.data?.reportType);

  useEffect(() => {
    if (ocrConfig.data) {
      setSseParams(ocrConfig.data);
    } else {
      setSseParams(undefined);
      setLoading(false);
      closeSSE();
    }
  }, [ocrConfig]);

  useEffect(() => {
    setLoading(textLoading || jsonLoading);
  }, [textLoading, jsonLoading]);

  const handleCancel = () => {
    controllerRef?.abort();
    closeSSE();
    setOcrConfig({
      open: false,
    });
    setOcrDetail([]);
    setJsonLoading(false);
    setSseParams(undefined);
    setReportText('');
    setControllerRef(null);
  };

  useImperativeHandle(ref, () => ({
    closeRequest: handleCancel,
  }));

  const handleClose = () => {
    if (loading) {
      confirm({
        title: '温馨提醒',
        icon: <ExclamationCircleFilled />,
        content: '正在进行智能识别，保存/取消后本次识别任务将终止!',
        onOk() {
          handleCancel();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      handleCancel();
    }
  };

  const handleCopy = (text: string) => {
    copyText(text);
    message.success(t('已复制'));
  };

  if (!ocrConfig.open) return null;

  const renderText = (d = []) => {
    return d.map((item: any) => item.words).join('\n');
  };

  const ContainerText = (item: any, key: any) => {
    if (key === 'imagesType') {
      return dict?.imageType?.[item?.[key]];
    }

    if (key === 'type' && ocrConfig?.data?.reportType === 'inspectionReport') {
      return item?.[key] === '0' ? '肿瘤标志物' : '其他';
    }

    // if (key === 'tumorStaging') {
    //   return transformTumorStage(item?.[key]);
    // }
    return item?.[key];
  };

  const Container = () => {
    if (ocrConfig?.data?.reportType === 'inspectionReport') {
      const inspectionFields = {
        doctor: '送检医生',
        receivedTime: '接收时间',
        reportTime: '报告时间',
        laboratoryTestItems: '',
      };
      return ocrDetail?.reformatResults?.map((item: any, index: number) => (
        <div key={`${index}`}>
          {ocrDetail?.reformatResults?.length > 1 && (
            <div className={styles.label} style={{ padding: '12px 0' }}>
              数据{index + 1}.
            </div>
          )}
          <div className={styles['image-ocr']}>
            {Object.keys(inspectionFields)?.map((key: any) => {
              if (key !== 'laboratoryTestItems') {
                return (
                  <div key={key} className={styles['parsing-container']}>
                    <div className={styles['parsing-title']}>
                      <span className={styles.label}>{fields?.[key]}</span>
                      {/* <a onClick={() => handleCopy(item[key])}>复制</a> */}
                    </div>
                    <div className={styles.container}>{ContainerText(item, key)}</div>
                  </div>
                );
              }

              return item?.laboratoryTestItems?.map((el: any, i: number) => (
                <div key={`laboratoryTestItems_${i}`} className={styles['parsing-container']}>
                  <div className={styles['parsing-title']}>
                    <span className={styles.label}>
                      检验项目
                      {i + 1}
                    </span>
                  </div>
                  {Object.keys(fields).map(
                    (list) =>
                      el?.[list] && (
                        <div style={{ paddingLeft: 12 }} key={list}>
                          <div className={styles['parsing-title']}>
                            <span className={styles.label}>{fields?.[list]}</span>
                            <a onClick={() => handleCopy(el[list])}>复制</a>
                          </div>
                          <div className={styles.container}>{ContainerText(el, list)}</div>
                        </div>
                      ),
                  )}
                </div>
              ));
            })}
          </div>
        </div>
      ));
    }
    return (
      ocrDetail &&
      ocrDetail?.reformatResults?.map((item: any, index: number) => (
        <div key={`${index}`}>
          {ocrDetail?.reformatResults?.length > 1 && (
            <div className={styles.label} style={{ padding: '12px 0' }}>
              数据{index + 1}.
            </div>
          )}
          <div className={styles['image-ocr']}>
            {Object.keys(fields)?.map(
              (key: any) =>
                !whiteField.includes(key) && (
                  <div key={key} className={styles['parsing-container']}>
                    <div className={styles['parsing-title']}>
                      <span className={styles.label}>{fields?.[key]}</span>
                      {!notCopy.includes(key) && item[key] && (
                        <a onClick={() => handleCopy(item[key])}>复制</a>
                      )}
                    </div>
                    <div className={styles.container}>{ContainerText(item, key)}</div>
                  </div>
                ),
            )}
          </div>
        </div>
      ))
    );
  };

  return (
    <div className={styles['imageOcr-parsing']}>
      <CloseOutlined className={styles.closeBtn} onClick={handleClose} />
      <div className={styles['parsing-title']} style={{ marginBottom: 12 }}>
        <span className={styles.label}>智能识别结果</span>
        {textLoading && <a>{t('图片OCR识别中...')}</a>}
        {jsonLoading && <a>{t('字段结构化过程中...')}</a>}
        {error && <a style={{ color: token.colorError }}>{t('智能识别失败')}</a>}
      </div>
      <Spin
        spinning={connectLoading || jsonLoading}
        tip={connectLoading ? t('图片OCR识别中...') : t('字段结构化过程中...')}
      >
        {reportText && !ocrDetail?.reformatResults?.length && (
          <pre className={styles['report-text']}>{reportText}</pre>
        )}
        {Container()}
        {!ocrDetail?.reformatResults?.length && !connectLoading && !jsonLoading && !textLoading && (
          <Empty style={{ paddingTop: 100 }} description={t('暂无数据')} />
        )}
        {(connectLoading || jsonLoading) && <div style={{ height: 140 }} />}
      </Spin>
    </div>
  );
});

export default ImageOcrParsing;
