import { useLocales } from '@/Locales';
import { uploadFile } from '@/services/common';
import { base64ToFile, previewFile } from '@/utils';
import { message, Modal, Spin } from '@sinohealth/butterfly-ui-components';
import { Editor as ImageEditor } from '@sinohealth/tiny-image-editor';
import React, { useImperativeHandle, useRef, useState } from 'react';
import styles from './index.less';

const menus = [
  'crop',
  'history',
  'download',
  // 'draw',
  'flip',
  'reset',
  // 'rotate',
  // 'rect',
  // 'circle',
  'text',
  'upload',
  'drag',
  // 'scale',
  // 'arrow',
  'mosaic',
  'undo',
  'redo',
];
const ImageEditorModal = (props: any, ref: any) => {
  const { title = '编辑图片' } = props;
  const [loading, setLoading] = useState(false); // 处理上传图片中
  const [open, setOpen] = useState(false);
  const [rootUrl, setRootUrl] = useState('');
  const editorRef = useRef<any>(null);
  const { locales } = useLocales();

  useImperativeHandle(ref, () => {
    return {
      openModal: handleOpen,
      closeModal: handleClose,
    };
  });

  const handleOpen = ({ src, base64 }: any) => {
    if (base64) {
      setRootUrl(base64);
    } else {
      setRootUrl(src);
    }
    downloadImage(src, base64);
    setOpen(true);
    setLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
    props?.onCancel && props?.onCancel(null);
  };
  const handleOK = async () => {
    setLoading(true);
    // 重置缩放
    // await editorRef.current.resetRef.current.onResetZoom();
    // 重置拖拽
    // await editorRef.current.resetDragRef.current.onResetDrag();
    editorRef.current.downloadRef.current
      .download()
      .then(({ downLoadUrl }: any) => {
        try {
          const file = base64ToFile(downLoadUrl, `编辑裁剪图片_${Date.now()}}.png`);
          // console.log(file);
          const formData = new FormData();
          formData.append('file', file);
          uploadFile(formData)
            .then((res) => {
              setOpen(false);
              props.onOk && props.onOk(res, rootUrl);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (e) {
          message.error('保存失败');
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadImage = async (url: string, base64: string) => {
    // 发送请求获取图片的二进制数据
    // 获取文件名
    const fileName = 'image.png';
    if (!base64) {
      const response = await fetch(previewFile(url));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // console.log('response', response);
      // 将响应转换为 Blob 对象
      const blob = await response.blob();

      // 将 Blob 转换为 File 对象
      const file = new File([blob], fileName, { type: blob.type });
      // console.log(file);
      editorRef.current.uploadRef.current.upload({ target: { files: [file] } });
    } else {
      const fileType = getFileTypeFromBase64(base64) || 'image/png';
      const file = base64ToFile(base64, fileName, fileType);
      editorRef.current.uploadRef.current.upload({ target: { files: [file] } });
    }
  };

  const getFileTypeFromBase64 = (base64String: string): string | null => {
    const regex = /^data:([a-zA-Z0-9/\-+]+);base64,/;

    const match = base64String.match(regex);
    if (match && match[1]) {
      return match[1];
    }

    return null;
  };

  return (
    <Modal
      title={title}
      style={{ top: 30 }}
      destroyOnClose
      maskClosable={false}
      keyboard={false}
      open={open}
      width={1100}
      onCancel={handleClose}
      onOk={handleOK}
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <div className={styles.imageEditor}>
          {/* @ts-ignore */}
          <ImageEditor
            ref={editorRef}
            // @ts-ignore
            menus={menus}
            // url={getDownloadFileUrl(rootUrl)}
            // @ts-ignore
            lang={locales === 'en' ? 'en' : 'zh'}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default React.forwardRef(ImageEditorModal);
