import ImageOcrParsing from '@/pages/patient/detail/components/ImageOcrParsing';
import { OcrContext } from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import { useOcrContextValue } from '@/pages/patient/hooks/useOcr';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './index.less';

const OcrFlex = (props: any) => {
  const { ocrConfig } = useContext(OcrContext);
  // const [firstRender, setFirstRender] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null); // 新增 ref 用于引用 DOM 节点
  const [height, setHeight] = useState<number>(0); // 新增状态用于保存高度
  useEffect(() => {
    const currentContainer: any = containerRef.current;
    if (currentContainer.hasChildNodes()) {
      setHeight(currentContainer?.firstChild?.offsetHeight); // 设置高度
    } else {
      setHeight(currentContainer.offsetHeight); // 设置高度
    }
  }, [containerRef, ocrConfig, props?.change]); // 当 ref 更新时重新计算高度

  return (
    <div className={styles.flex}>
      <div className={styles.left} ref={containerRef}>
        {props?.children}
      </div>
      <ImageOcrParsing ocrConfig={ocrConfig} height={height - 90} />
    </div>
  );
};

export default OcrFlex;

export const OcrContextProvider = (props: any) => {
  // const [ocrConfig, setOcrConfig] = useState<any>({});
  // const [loading, setLoading] = useState(false);
  // const [ocrDetail, setOcrDetail] = useState<any>([]);
  // const [reportText, setReportText] = useState(''); // 识别的文本
  // const [finished, setFinished] = useState(false); // 是否识别完成
  // const [controllerRef, setControllerRef] = useState<AbortController | null>(null);

  const { ocrContextValue } = useOcrContextValue();
  // const ocrContextValue = useMemo(() => {
  //   return {
  //     ocrConfig,
  //     setOcrConfig,
  //     ocrDetail,
  //     setOcrDetail,
  //     loading,
  //     setLoading,
  //     controllerRef,
  //     setControllerRef,
  //     reportText,
  //     setReportText,
  //     finished,
  //     setFinished,
  //   };
  // }, [
  //   ocrConfig,
  //   setOcrConfig,
  //   loading,
  //   setLoading,
  //   ocrDetail,
  //   setOcrDetail,
  //   controllerRef,
  //   setControllerRef,
  //   reportText,
  //   setReportText,
  //   finished,
  //   setFinished,
  // ]);

  return (
    <OcrContext.Provider value={ocrContextValue}>
      <OcrFlex change={props?.change}>{props.children}</OcrFlex>
    </OcrContext.Provider>
  );
};
