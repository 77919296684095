import { apiPrefix, appName, baseURL, scope } from '@/config/base';
import locationDictData from '@/hooks/dict/locationDictData';
import { useLocales } from '@/Locales';
import { getToken } from '@/utils/cookies';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { message } from '@sinohealth/butterfly-ui-components';
import { useContext, useEffect, useRef, useState } from 'react';
import { OcrContext } from '../detail/components/PatientTabs/components/TabItem';

export const useSseSocket = () => {
  const [sseParams, setSseParams] = useState<any>();
  const token = getToken();
  const [abortController, setAbortController] = useState(new AbortController());
  const { setOcrDetail, reportText, setFinished, setReportText } = useContext(OcrContext);
  const { t } = useLocales();
  // const [reportText, setReportText] = useState(''); // 识别的文本
  const [connectLoading, setConnectLoading] = useState(false); // 是否正在连接
  const [textLoading, setTextLoading] = useState(false);
  const [jsonLoading, setJsonLoading] = useState(false);
  const [error, setError] = useState(false);
  const retryCountRef = useRef(0); // 重试次数计数器

  const calcTumorMarkerItem = (code: string) => {
    const tumorMarker = locationDictData.tumorMarker;
    return tumorMarker.filter((el) => el.code === code)?.[0];
  };

  // 通信事件
  const contact = async () => {
    // messageData = { ...messageData, do_stream: modelArg.do_stream }; // 请求参数
    // receivedDataRef.current = '';

    setTextLoading(true);
    setConnectLoading(true);
    setReportText('');
    setFinished(false);
    setError(false);
    setOcrDetail([]);
    fetchEventSource(`${baseURL}${apiPrefix}/patient/report/recognition/text`, {
      method: 'POST',
      // 添加请求头
      headers: {
        'X-Token': token || '',
        biz: scope,
        scope,
        app: appName,
        'Content-Type': 'application/json',
      },
      // 传参必须保证是json
      body: JSON.stringify(sseParams),
      // abortController.signal 提供了一个信号对象给 fetchEventSource 函数。
      // 如果在任何时候你想取消正在进行的 fetch 操作，你可以调用
      // abortController.abort()。这会发出关联任务的信号，你可以使用
      // AbortController 的信号来检查异步操作是否已被取消。
      signal: abortController.signal,
      openWhenHidden: true, // 切换标签页时连接不关闭
      async onopen(response) {
        console.log('response', response);
        if (response.ok) {
          // 连接成功
          setConnectLoading(false);
          console.log('连接成功');
        } else {
          console.log('连接失败');
          setTextLoading(false);
          setConnectLoading(false);
          setJsonLoading(false);
          setError(true);
        }
      },
      onmessage(msg: { data: any; event: string }) {
        // const eventType = msg.event; // 监听event的具名事件
        // console.log({ msg });
        const data = JSON.parse(msg.data || '{}');
        const { content, finished } = data;
        !finished && setReportText((prev: any) => `${prev}${content || ''}`);
        setFinished(finished);
        // console.log({ content, finished });
        if (finished) {
          setTextLoading(false);
          setJsonLoading(true);
          const jsonContent = JSON.parse(content);
          console.log({ jsonContent });

          if (sseParams?.reportType === 'inspectionReport') {
            const list = jsonContent?.structuredResults?.map((el: any) => {
              const zhInspectionItems = el?.zhInspectionItems?.map((item: any) => {
                const markerItem = calcTumorMarkerItem(item.tumorMarkers);

                return {
                  ...item,
                  tumorMarkers: markerItem ? markerItem.value : item.tumorMarkers,
                };
              });

              return {
                ...el,
                zhInspectionItems,
              };
            });
            console.log({ list });
            setOcrDetail({
              ...jsonContent,
              structuredResults: list,
            });
          } else {
            setOcrDetail(jsonContent);
          }
          message.success(t('智能识别完成'));
        }
        // const { delta, finishReason } = data;
        // !finishReason && setReportText((prev: any) => `${prev}${delta?.content || ''}`);
        // if (finished) {
        //   setTextLoading(false);
        // }
      },
      onclose() {
        // 正常结束的回调
        console.log('closed');
        setJsonLoading(false);
        abortController?.abort();
      },
      onerror(err) {
        closeSSE();
        setConnectLoading(false);
        console.log('Error occurred:', err);
        setError(true);
        // return 1; // 返回 true 表示重试
        retryCountRef.current += 1; // 增加重试次数
        if (retryCountRef.current < 5) {
          console.log(`重试次数: ${retryCountRef.current}`);
          // contact(); // 重试
          return 1; // 返回 true 表示重试
        }
        console.log('达到最大重试次数，停止重试');
        retryCountRef.current = 0; // 重置重试次数
        throw new Error('closed');
      },
    });
  };

  // 终止连接方法，比如在切换模型时，你可能有必要终止上一次连接来避免问答串联
  const closeSSE = () => {
    abortController?.abort();
    setJsonLoading(false);
    setTextLoading(false);
    setAbortController(new AbortController());
  };

  useEffect(() => {
    sseParams && contact();

    return () => {
      closeSSE();
    };
  }, [sseParams]);

  return {
    setSseParams,
    closeSSE,
    reportText,
    setReportText,
    textLoading,
    jsonLoading,
    setJsonLoading,
    error,
    connectLoading,
  };
};

export const useSSE = (url: string, params?: any) => {
  const [data, setData] = useState('');
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const controller = useRef(new AbortController());

  const token = getToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchEventSource(url, {
          method: 'POST',
          headers: {
            'X-Token': token || '',
            biz: scope,
            scope,
            app: appName,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: '你的查询' }),
          ...params,
          signal: controller.current.signal,
          onmessage(ev) {
            setData(ev.data);
          },
          onclose() {
            console.log('Connection closed by server');
            setLoading(false);
          },
          onerror(err) {
            setError(err);
            console.error('Error received:', err);
            setLoading(false);
          },
        });
        setLoading(false);
      } catch (err) {
        setError(err);
        console.error('Fetch error:', err);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.current.abort();
    };
  }, [url, params]);

  return { data, error, loading };
};
