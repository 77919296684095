import BaseFormModal from '@/common/components/BaseFormModal';
// import Image from '@/components/Image';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import Empty from '@/pages/patient/detail/components/Empty';
import SortList from '@/pages/patient/detail/components/SortList';
import { addPatientChart, deletePatientChart, sortPatientChart } from '@/services/patient';
import { getUuid } from '@/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, message } from '@sinohealth/butterfly-ui-components';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import AddForm from './components/AddForm';
import ChartLine from './components/ChartLine';
import styles from './index.less';

const ChartsTab = () => {
  const formModal = useRef<any>();
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const patientInfoStatus = useRecoilValue(PatientInfoStatus);
  const { t } = useLocales();
  const { correlationCharts = [] } = patientInfo;
  const isEmpty = correlationCharts.length === 0;
  const handleCreate = () => {
    formModal.current?.openModal();
  };
  const handleSubmit = (formValue: any) => {
    return addPatientChart({
      ...formValue,
      patientId: patientInfo.patient.id,
    }).then(() => {
      patientInfo.update();
    });
  };
  const onSorted = (ids: string[]) => {
    sortPatientChart(ids).then(() => {
      message.success(t('排序成功'));
    });
  };
  const onEdit = (item: any) => {
    formModal.current?.openModal(item.data);
  };
  const onDelete = (item: any) => {
    deletePatientChart({
      id: item.data.id,
    }).then(() => {
      message.success(t('删除成功'));
      patientInfo.update();
    });
  };
  const listData = [...correlationCharts].map((item: any, i: any) => {
    return {
      title: item.name,
      id: item.id,
      data: item,
      content: (
        <div className={styles.body}>
          <div className={styles.desc}>
            <Label>简要说明</Label>&nbsp;&nbsp;&nbsp;{item.instruction || '--'}
          </div>
          <div className={styles.images}>
            <div className={styles.item}>
              <ChartLine
                config={{
                  name: item.name,
                  width: 600,
                  xAxisUnit: item.xAxisUnit,
                  yAxisUnit: item.yAxisUnit,
                  correlationChartDatas: item.correlationChartDatas,
                  key: getUuid(),
                }}
              />
              {/* <Image preview src={item.thumbnail} /> */}
            </div>
          </div>
        </div>
      ),
    };
  });

  return (
    <>
      {isEmpty && <Empty btnText={t('新增趋势图')} onCreate={handleCreate} />}
      {!isEmpty && (
        <div style={{ width: '100%' }}>
          <div style={{ marginBottom: 12 }}>
            <Button
              shape="round"
              type="primary"
              disabled={!patientInfoStatus.edit}
              onClick={handleCreate}
            >
              <PlusCircleOutlined /> {t('新增趋势图')}
            </Button>
          </div>
          <SortList datasource={listData} onSorted={onSorted} onEdit={onEdit} onDelete={onDelete} />
        </div>
      )}
      <BaseFormModal
        width={800}
        title={t('新增趋势图')}
        formModal={formModal}
        onSubmit={handleSubmit}
        FormContent={AddForm}
        footer={null}
      />
    </>
  );
};

export default ChartsTab;
