import { httpImageRecognitionDelete, httpImageRecognitionDetail } from '@/services/unionward';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useImageRecogniteDetail = () => {
  const [params] = useSearchParams();
  const taskId = params.getAll('taskId')[0];
  const [reloadRecogniteDetail, setReloadRecogniteDetail] = useState(false);
  const [imageRecognitionDetail, setImageRecognitionDetail] = useState<any>();
  const [currentImageUrl, setCurrentImageUrl] = useState<any>(); // 当前图片的识别内容
  const [imageProcessing, setImageProcessing] = useState<any>();
  const [intelligentRecognition, setIntelligentRecognition] = useState<any>();

  const httpImageRecognitionDetailReq = async () => {
    try {
      const res: any = await httpImageRecognitionDetail({ taskId });
      console.log(res);
      setImageRecognitionDetail(res);
      setImageProcessing(res?.imageProcessingData);
      setCurrentImageUrl(res?.imageProcessingData?.items?.[0]);
      setIntelligentRecognition(res?.intelligentRecognitionData);
    } catch (error) {
      console.log(error);
    } finally {
      setReloadRecogniteDetail(false);
    }
  };

  useEffect(() => {
    taskId && httpImageRecognitionDetailReq();
  }, [taskId]);

  useEffect(() => {
    reloadRecogniteDetail && httpImageRecognitionDetailReq();
  }, [reloadRecogniteDetail]);

  return {
    setReloadRecogniteDetail,
    imageRecognitionDetail,
    imageProcessing,
    intelligentRecognition,
    setCurrentImageUrl,
    currentImageUrl,
  };
};

/** 删除智能识别任务 */
export const useImageRecogniteDelete = (fileType?: string) => {
  const [params] = useSearchParams();
  const patientId = params.get('id') || params.get('patientId');

  const [deleteTaskId, setDeleteTaskId] = useState('');

  const httpImageRecognitionDeleteReq = async () => {
    try {
      // const result: any = await httpImageRecognitionFind({
      //   patientId,
      //   recordId: undefined, // 记录id
      //   taskType: 'single', // 单模块:single,多模块:multi
      //   moduleType: fileType,
      // });
      // if (result?.id) {
      //   console.log('删除智能识别任务', res);
      // }
      const res = await httpImageRecognitionDelete({ taskId: deleteTaskId });
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteTaskId('');
    }
  };

  useEffect(() => {
    deleteTaskId && httpImageRecognitionDeleteReq();
  }, [deleteTaskId]);

  return { setDeleteTaskId };
};
