import locationDictData from '@/hooks/dict/locationDictData';
import { useImageRecogniteDelete } from '@/pages/combinedWard/hook/useImageRecognite';
import {
  DrugRecordCheckbox,
  GeneticTestingCheckbox,
  HospitalDischargeRecordsCheckbox,
  ImageReportCheckbox,
  InspectionReportCheckbox,
  MedicalRecordCheckbox,
  OtherInformationCheckbox,
  PathologyReportCheckbox,
  SurgicalRecordCheckbox,
} from '@/pages/speechLecture/hook/config';
import { speechFilterEditAtom } from '@/store/speech';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { OcrContext } from '../detail/components/PatientTabs/components/TabItem';

const { confirm } = Modal;

/**
 * @description: 患者详情图片智能识别结果回填处理
 * @author: KaifengLi
 * @param {any} props
 * @version: v2.27.1
 * @Date: 2024-12-12 11:35:23
 */
export const useInitOcrRes = (props: any) => {
  // closed 讲稿，保存时候需要关闭
  const { form, isEditing, reportType = 'medicalRecord', closed } = props;
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const { setDeleteTaskId } = useImageRecogniteDelete(reportType);

  const {
    ocrConfig,
    loading,
    ocrDetail,
    controllerRef,
    setOcrDetail,
    setControllerRef,
    setOcrConfig,
    setReportText,
    recognitTaskId,
    setRecognitTaskId,
  } = useContext(OcrContext);

  const calcTumorMarkerItem = (code: string) => {
    const tumorMarker = locationDictData.tumorMarker;
    return tumorMarker.filter((el) => el.code === code)?.[0];
  };

  /** 正在进行识别时，二次确认提示 del需要删除任务 */
  const handleSecondComfirm = (callback: any, del?: boolean) => {
    if (loading) {
      confirm({
        title: '温馨提醒',
        icon: <ExclamationCircleFilled />,
        content: '正在进行智能识别，保存/取消后本次识别任务将终止!',
        onOk() {
          if (del) {
            setDeleteTaskId(recognitTaskId);
            setRecognitTaskId('');
          }
          callback?.();
          handleCancel();
          setRecognitTaskId('');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      if (del) {
        setDeleteTaskId(recognitTaskId);
        setRecognitTaskId('');
      }
      callback?.();
      handleCancel();
    }
  };

  const handleCancel = () => {
    controllerRef?.abort();
    setOcrConfig({
      open: false,
    });
    setOcrDetail([]);
    setReportText('');
    setControllerRef(null);
  };

  const isValidTimeFormat = (timeString: string): boolean => {
    // 尝试解析时间为 "HH:mm:ss" 格式
    return dayjs(timeString, 'HH:mm:ss', true).isValid();
  };

  useEffect(() => {
    // console.log({ form, ocrConfig, ocrDetail, reportType, isEditing });
    if (
      ocrConfig?.data?.reportType === reportType &&
      !isEditing &&
      ocrDetail?.structuredResults?.length
    ) {
      const firstItem = ocrDetail?.structuredResults?.[0];
      if (firstItem) {
        const formValue = form?.getFieldsValue();
        console.log({ formValue });
        // 用药记录处理
        if (reportType === 'drugRecord') {
          const { endMedicationTime, startMedicationTime } = firstItem;
          Object.keys(formValue).forEach((el) => {
            if (!formValue[el] && firstItem[el] !== null) {
              formValue[el] = firstItem[el];
            }
            // 用药时期处理
            if (el === 'medicationTime') {
              formValue[el] =
                isValidTimeFormat(startMedicationTime) && isValidTimeFormat(endMedicationTime)
                  ? [dayjs(startMedicationTime), dayjs(endMedicationTime)]
                  : [];
            }
          });
        } else {
          Object.keys(formValue).forEach((el) => {
            if (!formValue?.[el] || formValue?.[el]?.length === 0) {
              formValue[el] = firstItem[el];
            }
            if (el === 'tumorStaging') {
              formValue[el] = '';
            }
          });
        }
        // console.log({ ocrDetail, formValue });
        form?.setFieldsValue(formValue);
      }
    }
  }, [ocrConfig, ocrDetail]);

  useEffect(() => {
    if (!speechFilterEditFlag && closed && ocrConfig.data) {
      handleCancel();
    }
  }, [closed, speechFilterEditFlag, ocrConfig]);

  return { handleSecondComfirm, handleCancel, recognitTaskId };
};

export const useOcrContextValue = () => {
  const [ocrConfig, setOcrConfig] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [ocrDetail, setOcrDetail] = useState<any>([]);
  const [reportText, setReportText] = useState(''); // 识别的文本
  const [finished, setFinished] = useState(false); // 是否识别完成
  const [recognitTaskId, setRecognitTaskId] = useState(''); // 任务id
  const [controllerRef, setControllerRef] = useState<AbortController | null>(null);

  const ocrContextValue = useMemo(() => {
    return {
      ocrConfig,
      setOcrConfig,
      ocrDetail,
      setOcrDetail,
      loading,
      setLoading,
      controllerRef,
      setControllerRef,
      reportText,
      setReportText,
      finished,
      setFinished,
      recognitTaskId,
      setRecognitTaskId,
    };
  }, [
    ocrConfig,
    setOcrConfig,
    loading,
    setLoading,
    ocrDetail,
    setOcrDetail,
    controllerRef,
    setControllerRef,
    reportText,
    setReportText,
    finished,
    setFinished,
    recognitTaskId,
    setRecognitTaskId,
  ]);

  return { ocrConfig, ocrContextValue };
};

/**
 * @description: 构造字段枚举字段
 * @author: KaifengLi
 * @param {string} field
 * @version: v2.27.1
 * @Date: 2024-12-12 14:41:47
 */
export const useOcrFidle = (field: string, recognite?: boolean) => {
  const fields: any = {};

  switch (field) {
    case 'medicalRecord':
      MedicalRecordCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'hospitalDischargeRecord':
      HospitalDischargeRecordsCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'imageReport':
      ImageReportCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'surgicalRecord':
      SurgicalRecordCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'pathologyReport':
      PathologyReportCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'inspectionReport':
      InspectionReportCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      if (!recognite) {
        fields.itemName = '指标名称';
      } else {
        fields.itemName = '检验项目';
      }
      break;
    case 'geneticTesting':
      GeneticTestingCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    case 'drugRecord':
      DrugRecordCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      fields.medicationTime = '用药时期';
      fields.startMedicationTime = '开始用药时间';
      fields.endMedicationTime = '结束用药时间';
      break;
    case 'otherInformation':
      OtherInformationCheckbox?.forEach((el) => {
        fields[el.key] = el.value;
      });
      break;
    default:
      break;
  }

  return fields;
};

export const useFieldToLabel = (field: string) => {
  let fields: any = {};

  switch (field) {
    case 'medicalRecord':
      fields = {
        patientInfoDesc: '患者基本信息',
        admissionTime: '入院时间',
        chiefComplaint: '主诉',
        presentIllnessHistory: '现病史',
        pastMedicalHistory: '既往史',
        personalHistory: '个人史',
        menstrualHistory: '月经史',
        marriageHistory: '婚育史',
        familyHistory: '家族史',
        specialtySituation: '专科情况',
        physicalExamination: '体格检查',
        auxiliaryExamination: '辅助检查',
        admissionDiagnose: '入院诊断',
      };
      break;
    case 'hospitalDischargeRecord':
      fields = {
        dischargeTime: '出院时间',
        admissionTime: '入院时间',
        admissionCondition: '入院情况',
        admissionDiagnosis: '入院诊断',
        treatmentCourse: '诊疗经过',
        dischargeCondition: '出院情况',
        dischargeDiagnosis: '出院诊断',
        dischargeOrder: '出院医嘱',
      };
      break;
    case 'imageReport':
      fields = {
        receivedTime: '接收时间',
        reportTime: '报告时间',
        imageType: '检查方法',
        examinationBodySite: '检查部位',
        examinationFindings: '影像表现',
        examinationConclusion: '诊断意见',
      };
      break;
    case 'surgicalRecord':
      fields = {
        surgicalTime: '手术时间',
        surgicalName: '手术名称',
        preoperativeDiagnose: '术前诊断',
        postoperativeDiagnose: '术后诊断',
        surgicalCourse: '手术经过',
        surgicalObservations: '术中所见',
      };
      break;
    case 'pathologyReport':
      fields = {
        receivedTime: '接收时间',
        reportTime: '报告时间',
        staging: '病理TNM分期',
        examinationPurpose: '检查目的',
        summary: '镜下描述',
        immunohistochemistry: '免疫组化',
        diagnose: '病理诊断',
      };
      break;
    case 'inspectionReport':
      fields = {
        doctor: '送检医生',
        receivedTime: '接收时间',
        reportTime: '报告时间',
        itemName: '指标中文名称',
        indicatorName: '指标英文名称',
        resultValue: '结果',
        unit: '单位',
        normalRange: '参考范围',
      };
      break;
    case 'geneticTesting':
      fields = {
        receivedTime: '接收时间',
        reportTime: '报告时间',
        itemName: '检测项目',
        sampleType: '样本类型',
        resultDescription: '结果描述',
      };
      break;
    case 'drugRecord':
      fields = {
        medicalOrderTime: '医嘱时间',
        department: '科室',
        adviceType: '医嘱类型',
        startMedicationTime: '开始用药时间',
        endMedicationTime: '结束用药时间',
        executionTime: '执行时间',
        medicationPlan: '医嘱内容',
        adverseReaction: '不良反应',
        therapeuticEffect: '治疗效果',
      };
      break;
    case 'otherInformation':
      fields = {
        informationDate: '资料时间',
        informationType: '资料类型',
        description: '描述',
      };
      break;
    default:
      break;
  }
  return fields;
};
