/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { useLocales } from '@/Locales';
import { formatToDate, transformTumorStage } from '@/utils';
import {
  cstatus,
  ImageRecogniteStatus,
  MatchStatus,
  MatchStatusColor,
  UnionConsultaType,
} from '@/utils/constant';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from '@sinohealth/butterfly-ui-antd/lib/table';
import {
  Badge,
  Button,
  Input,
  Popover,
  Space,
  Tag,
  Tooltip,
} from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PatientTag from '../patientCaseTag/components/PatientTag';

/**
 * @description: 联合病房-MDT团队详情 - 团队病历 - table
 * @author: KaifengLi
 * @version: v2.22.0
 * @Date: 2024-09-04 10:53:56
 */
export const useTeamMedicalColumns = (actionRender: React.ReactNode | any) => {
  const { t } = useLocales();

  const columns: ColumnsType<any> = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('患者姓名'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: t('主治医生'),
      dataIndex: 'doctorName',
      key: 'doctorName',
      width: 150,
    },
    {
      title: t('医院名称'),
      dataIndex: 'hospitalName',
      key: 'hospitalName',
      width: 150,
    },
    {
      title: t('最新肿瘤分期'),
      dataIndex: 'tumorStage',
      key: 'tumorStage',
      width: 150,
      render: (text) => {
        return transformTumorStage(text) || '--';
      },
    },
    {
      title: t('最新诊断'),
      dataIndex: 'latestDiagnosis',
      key: 'latestDiagnosis',
      width: 260,
      render: (text) => {
        return <div style={{ wordBreak: 'break-all' }}>{text || '--'}</div>;
      },
    },
    {
      title: t('病例标签'),
      dataIndex: 'patientLabels',
      key: 'patientLabels',
      width: 260,
      render(item: any[]) {
        return item?.length ? (
          <Popover
            content={
              <Space size={10} style={{ maxWidth: 300 }} wrap>
                {item?.map((el, index: number) => {
                  return (
                    el?.type === 0 && (
                      <PatientTag
                        key={el?.id}
                        style={{ marginRight: 0 }}
                        border
                        color={el?.type === 0 ? 'processing' : 'warning'}
                      >
                        {el?.name}
                      </PatientTag>
                    )
                  );
                })}
              </Space>
            }
            placement="right"
            trigger="hover"
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: 12,
                maxHeight: 76,
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              {item?.map((el, index: number) => {
                return (
                  el?.type === 0 && (
                    <PatientTag
                      key={el?.id}
                      border
                      style={{ marginRight: 0 }}
                      color={el?.type === 0 ? 'processing' : 'warning'}
                    >
                      {el?.name}
                    </PatientTag>
                  )
                );
              })}
            </div>
          </Popover>
        ) : (
          '-'
        );
      },
    },
    {
      title: t('最近更新时间'),
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      width: 160,
      render: (text) => {
        return formatToDate(text, 'YYYY-MM-DD HH:mm:ss'); // TODO: 格式化时间
      },
    },
    {
      title: t('操作'),
      key: 'action',
      width: 160,
      fixed: 'right',
      render: (value: any, record: any) => actionRender(value, record),
    },
  ];
  return columns;
};

/**
 * @description: 联合病房-MDT团队详情 - 会诊记录- table
 * @author: KaifengLi
 * @version: v2.22.0
 * @Date: 2024-09-04 10:54:11
 */
export const useConsultNoteColumns = (renderAction?: React.ReactNode | any, type?: string) => {
  const { t } = useLocales();

  const columns: ColumnsType<any> = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('会诊时间'),
      dataIndex: 'startTime',
      key: 'startTime',
      width: 160,
      render(text: string, record: any, index: number): JSX.Element {
        return !type ? (
          <span>
            {record.mediaType === 'video'
              ? `${formatToDate(text, 'YYYY-MM-DD HH:mm')}~${
                  formatToDate(record?.endTime, 'YYYY-MM-DD HH:mm').split(' ')[1]
                }`
              : formatToDate(record?.createAt, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        ) : (
          <span>
            {record.mediaType === 'video'
              ? `${formatToDate(text, 'YYYY-MM-DD HH:mm:ss')}`
              : formatToDate(record?.createAt, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        );
      },
    },
    {
      title: t('会诊方式'),
      dataIndex: 'mediaType',
      key: 'mediaType',
      width: 120,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{t(UnionConsultaType[text]) || '--'}</span>;
      },
    },
    {
      title: t('会诊结果'),
      dataIndex: 'conclusion',
      key: 'conclusion',
      render(text: string, record: any, index: number): JSX.Element {
        return <div style={{ wordBreak: 'break-all' }}>{text || '--'}</div>;
      },
    },
    {
      title: t('操作'),
      key: 'action',
      width: 100,
      fixed: 'right',
      render:
        renderAction ||
        ((_: any, record: any) => (
          <Space size="middle">
            <Link to={`/combinedWard/mdtConsultDetail?id=${record.id}`}>{t('会诊详情')}</Link>
          </Space>
        )),
    },
  ];
  return columns;
};

/**
 * @description: 联合病房-团队会诊中心-table toolbar
 * @author: KaifengLi
 * @param {React} renderAction
 * @version: v2.22.0
 * @Date: 2024-09-04 13:55:21
 */
export const useCombinedWardConsult = (renderAction: React.ReactNode | any) => {
  const { t } = useLocales();
  const navigate = useNavigate();

  const columns: any = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 80,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('会诊时间'),
      dataIndex: 'startTime',
      key: 'startTime',
      width: 230,
      sorter: (a: any, b: any) => a.startTime - b.startTime,
      render(text: any, record: any): JSX.Element {
        return (
          <span>
            {record.mediaType === 'text'
              ? formatToDate(record?.createAt, 'YYYY-MM-DD HH:mm:ss')
              : formatToDate(record?.startTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        );
      },
    },
    {
      title: t('发起人'),
      dataIndex: 'creatorTeamDoctorName',
      key: 'creatorTeamDoctorName',
    },
    {
      title: t('发起医院'),
      dataIndex: 'creatorTeamHospitalName',
      key: 'creatorTeamHospitalName',
      render(text: any[]): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('发起团队'),
      dataIndex: 'creatorTeamName',
      key: 'creatorTeamName',
      render(text: any[]): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('会诊方式'),
      dataIndex: 'mediaType',
      key: 'mediaType',
      ellipsis: true,
      render(text: string): JSX.Element {
        return (
          <Tag color={text === 'video' ? 'processing' : 'warning'}>
            {t(UnionConsultaType[text])}
          </Tag>
        );
      },
    },
    {
      title: t('会诊主题'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: t('会诊患者'),
      dataIndex: 'zhConsultationPatients',
      key: 'zhConsultationPatients',
      render(text: any[], record: any): JSX.Element {
        return (
          <Space direction="horizontal" wrap>
            {text?.map((item) => (
              <Tag
                key={item?.id}
                color="processing"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/patient/detail?id=${item?.id}`)}
              >
                {/* <Link key={item?.id} to={`/patient/detail?id=${item?.id}`}> */}
                {item?.name}
                {/* </Link> */}
              </Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: t('简介'),
      dataIndex: 'introduction',
      key: 'introduction',
      width: 300,
      ellipsis: {
        showTitle: true,
      },
      render(text: string): JSX.Element {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      title: t('会诊状态'),
      dataIndex: 'status',
      key: 'status',
      render(text: number): JSX.Element {
        const color = ['#4288FF', '#FAAD14', '#E44444', 'rgba(11, 33, 69, 0.25)', '#00B793'];
        return <Badge color={color[text]} text={t(cstatus[text])} />;
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      width: 160,
      render: renderAction,
    },
  ];

  const homeColumns: any[] = [
    {
      title: t('会诊时间'),
      dataIndex: 'startTime',
      key: 'startTime',
      width: 140,
      // sorter: (a: any, b: any) => a.startTime - b.startTime,
      render(text: any, record: any): JSX.Element {
        return (
          <span>
            {record.mediaType === 'text'
              ? formatToDate(record?.createAt, 'YYYY-MM-DD HH:mm:ss')
              : formatToDate(record?.startTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        );
      },
    },
    {
      title: t('发起人'),
      dataIndex: 'creatorTeamDoctorName',
      key: 'creatorTeamDoctorName',
      width: 100,
    },
    {
      title: t('发起医院'),
      dataIndex: 'creatorTeamHospitalName',
      key: 'creatorTeamHospitalName',
      width: 150,
      render(text: any[]): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('会诊患者'),
      dataIndex: 'zhConsultationPatients',
      key: 'zhConsultationPatients',
      width: 150,
      render(text: any[]): JSX.Element {
        return (
          <Space direction="horizontal" wrap>
            {text?.map((item) => (
              <Tag
                color="processing"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/patient/detail?id=${item?.id}`)}
              >
                {item?.name}
              </Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      width: 90,
      render: renderAction,
    },
  ];

  const Toolbar = () => {
    return (
      <Button type="primary" onClick={() => navigate('/combinedWard/mdtConsultCreate')}>
        <PlusCircleOutlined />
        {t('创建会诊')}
      </Button>
    );
  };

  return { columns, Toolbar, homeColumns };
};

/**
 * @description: 联合病房-质控参考评分详情-评估概览
 * @author: KaifengLi
 * @version: v2.23.0
 * @Date: 2024-09-24 16:26:41
 */
export const useQualityScoreDetailColumns = () => {
  const { t } = useLocales();

  const columns: any = [
    {
      title: t('条目'),
      dataIndex: 'index',
      key: 'index',
      width: 80,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('评分指标'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('评分规则'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('指标权重'),
      dataIndex: 'weight',
      key: 'weight',
      width: 160,
      render(text: string, record: any, index: number) {
        return <span>{text}%</span>;
      },
    },
    {
      title: t('本病例指标得分'),
      dataIndex: 'score',
      key: 'score',
      width: 160,
      render(text: string, record: any, index: number) {
        return text !== null ? text : '--';
      },
    },
  ];

  return { columns };
};

/**
 * @description: 联合病房-质控参考评分详情-本病例情况
 * @author: KaifengLi
 * @version: v2.23.0
 * @Date: 2024-09-24 17:42:46
 */
export const useQualityScoreCaseColumns = (renderAction: React.ReactNode | any) => {
  const { t } = useLocales();

  const columns: any = [
    {
      title: t('条目'),
      dataIndex: 'index',
      key: 'index',
      width: 60,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('评分规则'),
      dataIndex: 'description',
      key: 'description',
      minWidth: 300,
    },
    {
      title: t('指标状态'),
      dataIndex: 'matchStatus',
      key: 'matchStatus',
      width: 90,
      render(text: string, record: any, index: number) {
        return <span style={{ color: MatchStatusColor[text] }}>{MatchStatus[text]}</span>;
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      width: 145,
      render: renderAction,
    },
  ];

  return { columns };
};

export const useAssociatedDetailsColumns = (props: any) => {
  const { dict } = props;
  const { t } = useLocales();

  const admissionRecordColumns = [
    {
      title: t('入院时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('主诉'),
      dataIndex: 'chiefComplaint',
      key: 'chiefComplaint',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('现病史'),
      dataIndex: 'presentIllness',
      key: 'presentIllness',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const dischargeRecordColumns = [
    {
      title: t('出院时间'),
      dataIndex: 'dischargeTime',
      key: 'dischargeTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('入院情况'),
      dataIndex: 'admitDescription',
      key: 'admitDescription',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('治疗经过'),
      dataIndex: 'treatmentCourse',
      key: 'treatmentCourse',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const imageReportColumns = [
    {
      title: t('检查时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('影像类型'),
      dataIndex: 'imagesType',
      key: 'imagesType',
      render(text: string) {
        return dict?.imageType?.[text];
      },
    },
    {
      title: t('检查部位'),
      dataIndex: 'parts',
      key: 'parts',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('检查所见'),
      dataIndex: 'reportDiagnose',
      key: 'reportDiagnose',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const surgicalColumns = [
    {
      title: t('手术时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('手术名称'),
      dataIndex: 'surgeryName',
      key: 'surgeryName',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('手术经过'),
      dataIndex: 'procedures',
      key: 'procedures',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const pathologicalReportColumns = [
    {
      title: t('检查时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('病理概述'),
      dataIndex: 'summary',
      key: 'summary',
      ellipsis: {
        showTitle: true,
      },
      render(text: string, record: any) {
        return <div>{record?.diagnosisDescription?.summary || '-'}</div>;
      },
    },
    {
      title: t('免疫组化'),
      dataIndex: 'immunohistochemistry',
      key: 'immunohistochemistry',
      ellipsis: {
        showTitle: true,
      },
      render(text: string, record: any) {
        return <div>{record?.diagnosisDescription?.immunohistochemistry || '-'}</div>;
      },
    },
  ];

  const inspectionColumns = [
    {
      title: t('检查时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('检验项目'),
      dataIndex: 'zhInspectionItems',
      key: 'zhInspectionItems',
      ellipsis: {
        showTitle: true,
      },
      render(text: string, record: any) {
        return (
          <div>
            {record?.zhInspectionItems.length
              ? record?.zhInspectionItems?.map((el: any) => (
                  <div key={el?.id}>
                    {el?.type === '0'
                      ? dict?.tumorMarker?.[Number(el?.tumorMarkers)]
                      : el?.tumorMarkers}
                    {el?.resultValueItem ? `, ${el?.resultValueItem}` : ''}
                    {el?.unit ? `, ${el?.unit}` : ''}
                  </div>
                ))
              : '-'}
          </div>
        );
      },
    },
    {
      title: t('描述'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const geneticColumns = [
    {
      title: t('检查时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('结果描述'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const medicaRecordColumns = [
    {
      title: t('医嘱时间'),
      dataIndex: 'recordTime',
      key: 'recordTime',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('用药方案'),
      dataIndex: 'drugPlan',
      key: 'drugPlan',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('不良反应'),
      dataIndex: 'adverseReactions',
      key: 'adverseReactions',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const comparePicturesColumns = [
    {
      title: t('对比图片名称'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('简要说明'),
      dataIndex: 'instruction',
      key: 'instruction',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const chartTrendColumns = [
    {
      title: t('趋势图名称'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('简要说明'),
      dataIndex: 'instruction',
      key: 'instruction',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  const referencesColumns = [
    {
      title: t('资料时间'),
      dataIndex: 'informationDate',
      key: 'informationDate',
      render(text: string) {
        return formatToDate(text);
      },
    },
    {
      title: t('资料类型'),
      dataIndex: 'informationType',
      key: 'informationType',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('描述'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  return {
    admissionRecordColumns,
    dischargeRecordColumns,
    imageReportColumns,
    surgicalColumns,
    pathologicalReportColumns,
    inspectionColumns,
    geneticColumns,
    medicaRecordColumns,
    comparePicturesColumns,
    chartTrendColumns,
    referencesColumns,
  };
};

/**
 * @description: 编辑自定义标签->表格列
 * @author: KaifengLi
 * @param {React} renderAction
 * @version: v2.25.0
 * @Date: 2024-10-23 16:55:22
 */
export const useCustomTagColumns = (
  renderAction: React.ReactNode | any,
  DragHandle: React.ReactNode | any,
  edit = false,
) => {
  const { t } = useLocales();

  const columns: any = [
    {
      title: t('排序'),
      dataIndex: 'sort',
      width: 100,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: t('标签名称'),
      dataIndex: 'name',
      key: 'name',
      className: 'drag-visible',
      minWidth: 300,
      render: (text: any, record: any) => {
        return edit ? <Input value={record.name} /> : text;
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      className: 'drag-visible',
      width: 145,
      render: renderAction,
    },
  ];

  return { columns };
};

export const useConsultModelColumns = (renderAction: React.ReactNode | any) => {
  const { t } = useLocales();
  const navigate = useNavigate();

  const columns: any = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('会诊时间'),
      dataIndex: 'startTime',
      key: 'startTime',
      width: 130,
      // sorter: (a: any, b: any) => a.startTime - b.startTime,
      render(text: any, record: any): JSX.Element {
        return (
          <span>
            {record.mediaType === 'text'
              ? formatToDate(record?.createAt, 'YYYY-MM-DD HH:mm:ss')
              : formatToDate(record?.startTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        );
      },
    },
    {
      title: t('发起人'),
      dataIndex: 'creatorTeamDoctorName',
      key: 'creatorTeamDoctorName',
      width: 100,
    },
    {
      title: t('发起医院'),
      dataIndex: 'creatorTeamHospitalName',
      key: 'creatorTeamHospitalName',
      width: 160,
      render(text: any[]): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('会诊主题'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: t('会诊患者'),
      dataIndex: 'zhConsultationPatients',
      key: 'zhConsultationPatients',
      width: 150,
      render(text: any[]): JSX.Element {
        return (
          <Space direction="horizontal" wrap>
            {text?.map((item) => (
              <Tag
                color="processing"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/patient/detail?id=${item?.id}`)}
              >
                {item?.name}
              </Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      width: 160,
      render: renderAction,
    },
  ];

  return { columns };
};

/**
 * @description: 联合病房-病例更新及点评记录
 * @author: KaifengLi
 * @param {React} renderAction 操作列
 * @param {any} dict 字典
 * @version: v2.25.0
 * @Date: 2024-12-05 17:10:03
 */
export const useCaseRecordsCommentsColumns = (renderAction: React.ReactNode | any, dict: any) => {
  const { t } = useLocales();

  const columns: any = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('更新时间'),
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      width: 110,
      render(text: any, record: any): JSX.Element {
        return <span>{text ? formatToDate(text, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>;
      },
    },
    {
      title: t('更新人'),
      dataIndex: 'employeeName',
      key: 'employeeName',
      width: 100,
    },
    {
      title: t('所属医院'),
      dataIndex: 'hospitalName',
      key: 'hospitalName',
      width: 120,
      render(text: any[]): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('更新模块'),
      dataIndex: 'module',
      key: 'module',
      width: 110,
      render(text: any): JSX.Element {
        return <span>{dict?.updateModule?.[text]}</span>;
      },
    },
    {
      title: t('模块对应时间'),
      dataIndex: 'reportDate',
      key: 'reportDate',
      width: 140,
      render(text: any, record: any): JSX.Element {
        return <span>{text ? formatToDate(text, 'YYYY-MM-DD') : '-'}</span>;
      },
    },
    {
      title: t('更新字段'),
      dataIndex: 'fieldsList',
      key: 'fieldsList',
      width: 160,
      render(text: any[]): JSX.Element {
        return <span>{text?.map((el) => el?.fieldsName).join('、')}</span>;
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      width: 110,
      render: renderAction,
    },
  ];

  return { columns };
};

/**
 * @description: 批量图片识别管理-table列
 * @author: KaifengLi
 * @param {React} renderAction 操作列
 * @param {any} dict 字典
 * @version: v2.27.1
 * @Date: 2024-12-05 17:09:13
 */
export const useImageRecogniteColumns = (renderAction: React.ReactNode | any, dict: any) => {
  const { t } = useLocales();

  const columns: any = [
    {
      title: t('序号'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: t('患者名称'),
      dataIndex: 'patientName',
      key: 'patientName',
      width: 110,
      render(text: any, record: any): JSX.Element {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: t('图片上传时间'),
      dataIndex: 'uploadTime',
      key: 'uploadTime',
      width: 110,
      render(text: any, record: any): JSX.Element {
        return <span>{text ? formatToDate(text, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>;
      },
    },
    {
      title: t('智能识别时间'),
      dataIndex: 'recognitionTime',
      key: 'recognitionTime',
      width: 110,
      render(text: any, record: any): JSX.Element {
        return <span>{text ? formatToDate(text, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>;
      },
    },
    {
      title: t('操作角色'),
      dataIndex: 'operRole',
      key: 'operRole',
      width: 90,
    },
    {
      title: t('操作人'),
      dataIndex: 'operName',
      key: 'operName',
      width: 90,
    },
    {
      title: t('操作账号'),
      dataIndex: 'operAccount',
      key: 'operAccount',
      width: 100,
      render(text: any): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('项目'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: 120,
      render(text: any): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('机构'),
      dataIndex: 'orgName',
      key: 'orgName',
      width: 120,
      render(text: any): JSX.Element {
        return <span>{text}</span>;
      },
    },
    {
      title: t('任务类型'),
      dataIndex: 'taskType',
      key: 'taskType',
      width: 100,
      render(text: any): JSX.Element {
        return <span>{dict?.imageRecogniteType?.[text]}</span>;
      },
    },
    {
      title: t('识别状态'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render(text: any): JSX.Element {
        return (
          <Badge status={ImageRecogniteStatus[text]} text={dict?.imageRecogniteStatus?.[text]} />
        );
      },
    },
    {
      title: t('总耗时（秒）'),
      dataIndex: 'duration',
      key: 'duration',
      width: 120,
      render(text: any): JSX.Element {
        return <span>{text !== null ? `${text}s` : ''}</span>;
      },
    },
    {
      title: t('识别进度'),
      dataIndex: 'progress',
      key: 'progress',
      width: 120,
      render(text: any): JSX.Element {
        return <span>{text}/8</span>;
      },
    },
    {
      title: t('操作'),
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 80,
      render: renderAction,
    },
  ];

  return { columns };
};
