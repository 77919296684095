/* eslint-disable react/no-array-index-key */
import { useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import Horizontal from './components/Horizontal';
import Vertical from './components/Vertical';
import styles from './index.less';

interface TimeAxisProps {
  source: any[];
  edit?: boolean;
  direction?: 'VERTICAL' | 'HORIZONTAL';
  onRela?: (id: string, ty: string) => void; // 查看关联记录
  onEdit?: () => void; // 编辑成功
  onDelete?: () => void;
}

/**
 * @description: 时间轴组件-新版设计稿
 * @author: KaifengLi
 * @version: v2.28.0
 * @Date: 2024-12-24 10:37:28
 */
const TimeAxis: React.FC<TimeAxisProps> = (props) => {
  const { source = [], edit, direction, onRela, onEdit, onDelete } = props;

  const { setTimelineParams, patientId, saveSuccess } = useTimeLineSave();

  useEffect(() => {
    saveSuccess && onDelete?.();
  }, [saveSuccess]);

  return (
    <div
      className={classNames(
        styles['time-axis'],
        direction === 'HORIZONTAL' && styles['horizontal-flex'],
      )}
    >
      {direction === 'VERTICAL' &&
        source.map((item, index) => (
          <Vertical
            key={`${item.yearMonth}_${index}`}
            detail={item}
            edit={edit}
            onRela={onRela}
            onEdit={onEdit}
            onDelete={(val: any) => setTimelineParams({ ...val, patientId })}
          />
        ))}
      {direction === 'HORIZONTAL' &&
        source.map((item, index) => (
          <Horizontal
            key={`${item.yearMonth}_${index}`}
            edit={edit}
            detail={item}
            onRela={onRela}
            onEdit={onEdit}
            onDelete={(val: any) => setTimelineParams({ ...val, patientId })}
          />
        ))}
    </div>
  );
};

export default TimeAxis;
