import { useLocales } from '@/Locales';
import { speechLectureTypeAtom } from '@/store/speech';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Space } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import GenesForm from '../../../GenesForm';
import ImageForm from '../../../ImageForm';
import InspectForm from '../../../InspectForm';
import OtherForm from '../../../OtherForm';
import PathologyForm from '../../../PathologyForm';
import TreatmentForm from '../../../TreatmentForm';
import styles from './index.less';

interface AxisHeaderProps {
  canDrag: boolean;
  onSet: (val: boolean) => void;
  scaleFactor: number;
  onZoom: (type: 'in' | 'out') => void;
  edit?: boolean;
  direction?: 'VERTICAL' | 'HORIZONTAL';
  onChange: (axisDirection: 'VERTICAL' | 'HORIZONTAL') => void;
  onUpdate?: () => void;
}

/**
 * @description: 时间轴头部操作栏
 * @author: KaifengLi
 * @version: v2.28.0
 * @Date: 2024-12-24 10:50:03
 */
const AxisHeader: React.FC<AxisHeaderProps> = (props) => {
  const { canDrag, onSet, scaleFactor, onZoom, edit, direction = 'VERTICAL', onUpdate } = props;

  const speechLectureType = useRecoilValue(speechLectureTypeAtom);

  const { t } = useLocales();

  // 是否为时间轴讲稿
  const timelineLectureFlag = speechLectureType === 'timelineLecture';

  return (
    <div
      className={classNames(styles['axis-header'], timelineLectureFlag && styles['axis-lecture'])}
    >
      <div className={styles['header-container']}>
        {!timelineLectureFlag && <div className={styles['axis-title']}>{t('添加信息')}</div>}
        {edit && (
          <Space>
            <ImageForm edit={edit} button onSubmit={onUpdate} />
            <InspectForm edit={edit} button onSubmit={onUpdate} />
            <PathologyForm edit={edit} button onSubmit={onUpdate} />
            <GenesForm edit={edit} button onSubmit={onUpdate} />
            <TreatmentForm edit={edit} button onSubmit={onUpdate} />
            <OtherForm edit={edit} button onSubmit={onUpdate} />
          </Space>
        )}
      </div>
      <Space>
        <Radio.Group
          buttonStyle="solid"
          value={direction}
          onChange={(e) => {
            props.onChange?.(e.target.value);
          }}
        >
          <Radio.Button value="VERTICAL">{t('竖轴')}</Radio.Button>
          <Radio.Button value="HORIZONTAL">{t('横轴')}</Radio.Button>
        </Radio.Group>
        <div className={styles['scale-zoom']}>
          <Space.Compact>
            <Button
              type={canDrag ? 'primary' : 'default'}
              icon={<div className={classNames('iconfont icon-a-shoutuozhuai')} />}
              onClick={() => onSet(!canDrag)}
            />
            <Button icon={<MinusOutlined />} onClick={() => onZoom('out')} />
            <Input value={`${Math.round(scaleFactor * 100)}%`} readOnly style={{ width: 60 }} />
            <Button icon={<PlusOutlined />} onClick={() => onZoom('in')} />
          </Space.Compact>
        </div>
      </Space>
    </div>
  );
};

export default AxisHeader;
