export default {
  // 患者性别
  gender: [
    { label: '男', value: '0' },
    { label: '女', value: '1' },
    { label: '保密', value: '2' },
  ],
  // 患者性别
  genderDict: [
    { label: '男', value: '0' },
    { label: '女', value: '1' },
    { label: '未知', value: '2' },
  ],
  // 患者状态
  patientStatus: [
    { label: '待审核', value: 0 },
    { label: '待授权', value: 1 },
    { label: '待完善', value: 2 },
    { label: '已完善', value: 3 },
  ],
  // 医院等级
  gradeList: [
    { value: 1, label: '一级医院' },
    { value: 2, label: '二级医院' },
    { value: 3, label: '三级医院' },
  ],

  // 区域类别
  typeList: [
    {
      label: '国',
      value: 0,
    },
    {
      label: '省',
      value: 1,
    },
    {
      label: '市',
      value: 2,
    },
    {
      label: '区',
      value: 3,
    },
  ],
  // 菜单级别
  menuLevel: [
    {
      label: '一级菜单',
      value: 1,
    },
    {
      label: '二级菜单',
      value: 2,
    },
    {
      label: '按钮',
      value: 3,
    },
  ],
  // 医学影像类型
  imageType: [
    { label: 'CT', value: 1 },
    { label: 'X光', value: 2 },
    { label: 'US', value: 3 },
    { label: 'MRI', value: 4 },
    { label: 'PET-CT', value: 5 },
    { label: '其他', value: 6 },
  ],
  tumorMarker: [
    { value: '1', label: '甲胎蛋白(AFP)', code: 'AFP', referenceItem: '0～15', unit: 'ng/ml' },
    { value: '2', label: '癌胚抗原(CEA)', code: 'CEA', referenceItem: '0～5', unit: 'ng/ml' },
    { value: '3', label: '癌抗原50(CA50)', code: 'CA50', referenceItem: '0~20', unit: 'U/ml' },
    {
      value: '4',
      label: '癌抗原125(CA125)',
      code: 'CA125',
      referenceItem: '0.1～35',
      unit: 'U/ml',
    },
    {
      value: '5',
      label: '癌抗原153(CA15-3)',
      code: 'CA15-3',
      referenceItem: '0.1～25',
      unit: 'U/ml',
    },
    {
      value: '6',
      label: '癌抗原199(CA19-9)',
      code: 'CA19-9',
      referenceItem: '0.1～27',
      unit: 'U/ml',
    },
    {
      value: '7',
      label: '癌抗原724(CA72-4)',
      code: 'CA72-4',
      referenceItem: '0.1～7',
      unit: 'U/ml',
    },
    { value: '8', label: '癌抗原242(CA242)', code: 'CA242', referenceItem: '0～17', unit: 'U/ml' },
    {
      value: '9',
      label: '细胞角蛋白 211(CYFRA 21-1)',
      code: 'CYFRA 21-1',
      referenceItem: '0.10～4',
      unit: 'ng/ml',
    },
    {
      value: '10',
      label: '神经元特异性烯醇化酶，NSE',
      code: 'NSE',
      referenceItem: '0～16',
      unit: 'ng/ml',
    },
    {
      value: '11',
      label: '鳞状细胞癌抗原(SCC)',
      code: 'SCC',
      referenceItem: '< 1.5 ',
      unit: 'mg/L',
    },
    {
      value: '12',
      label: '总前列腺特异性抗原(TPSA)',
      code: 'TPSA',
      referenceItem: '0.01～4.0',
      unit: 'ng/ml',
    },
    {
      value: '13',
      label: '游离前列腺特异性抗原(FPSA)',
      code: 'FPSA',
      referenceItem: '0.01～2.0 FPSA/TPSA：> 0.15',
      unit: 'ng/ml',
    },
    {
      value: '14',
      label: '血清铁蛋白(SF)',
      code: 'SF',
      referenceItem: '男性：30～400 女性：13～150',
      unit: 'ng/ml',
    },
    {
      value: '15',
      label: '胃泌素前体释放肽(PROGRP)',
      code: 'PROGRP',
      referenceItem: '4～6',
      unit: 'pg/ml',
    },
  ],
  doctorTag: [
    {
      label: 'A类专家',
      value: '1',
    },
    {
      label: 'B类专家',
      value: '2',
    },
    {
      label: 'C类专家',
      value: '3',
    },
  ],
  activityStatus: [
    {
      label: '未开始',
      value: 'un_start',
    },
    {
      label: '进行中',
      value: 'in_progress',
    },
    {
      label: '已结束',
      value: 'finished',
    },
  ],
  nodeTypeSelect: [
    {
      label: '用药',
      value: 'DRUG_RECORD',
    },
    {
      label: '手术',
      value: 'SURGICAL_RECORD',
    },
    {
      label: '放疗',
      value: 'TREATMENT_RADIOTHERAPY',
    },
    {
      label: '其他',
      value: 'TREATMENT_OTHER_INFORMATION',
    },
  ],
  timelineOtherSelect: [
    {
      label: '诊断',
      value: 'DIAGNOSIS',
    },
    {
      label: 'MDT',
      value: 'MDT_CONCLUSION',
    },
    {
      label: '其他',
      value: 'OTHER_INFORMATION',
    },
  ],
  /** 点评记录 - 评论模块 */
  commentModule: [
    {
      label: '病例详情',
      value: 'medicalRecord',
    },
    {
      label: '质控情况',
      value: 'patientScore',
    },
  ],
  /** 病例更新及点评记录 - 更新模块 */
  updateModule: [
    { value: 'zh_patient', label: '基本信息' },
    { value: 'zh_medical_record', label: '入院记录' },
    { value: 'zh_hospital_discharge_records', label: '出院记录' },
    { value: 'zh_image_report', label: '影像报告' },
    { value: 'zh_surgical_record', label: '手术记录' },
    { value: 'zh_pathology_report', label: '病理报告' },
    { value: 'zh_inspection_report', label: '检验报告' },
    { value: 'zh_genetic_testing', label: '基因检测' },
    { value: 'zh_drug_records', label: '用药记录' },
    { value: 'zh_patient_mdt_issue', label: 'MDT议题' },
    { value: 'zh_mdt_compariso_chart', label: '对比图片' },
    { value: 'zh_mdt_correlation_chart', label: '趋势图' },
    { value: 'zh_other_information', label: '参考文献' },
  ],
  mdtIssueType: [
    {
      label: '投票型议题',
      value: 'vote',
    },
    {
      label: '讨论型议题',
      value: 'discuss',
    },
  ],
  /** 图片识别状态 */
  imageRecogniteStatus: [
    { value: 'notStarted', label: '未开始', color: 'default' },
    { value: 'parsing', label: '识别中', color: 'processing' },
    { value: 'completed', label: '已结束', color: 'success' },
    { value: 'fail', label: '识别失败', color: 'error' },
  ],
  /** 任务类型 */
  imageRecogniteType: [
    { value: 'multi', label: '多模块', color: 'default' },
    { value: 'single', label: '单模块', color: 'processing' },
  ],
};
