import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { useInitOcrRes } from '@/pages/patient/hooks/useOcr';
import { addPatientSurgical, updatePatientSurgical } from '@/services/patient';
import { Form as AntdForm, Input } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const requiredRule = { required: true, message: '该字段为必填项' };

const SurgicalRecordsTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  /** 患者详情图片智能识别结果回填处理 */
  const { handleSecondComfirm, recognitTaskId } = useInitOcrRes({
    form,
    isEditing: readOnly,
    reportType: 'surgicalRecord',
  });

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleCancel = () => {
    patientInfo.update();
    handleChangeReadOnly();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((formValue) => {
        const params = {
          ...formValue,
          patientId: patientInfo.patient.id,
        };
        const api = params.id ? updatePatientSurgical : addPatientSurgical;
        if (!formValue?.id) {
          params.recognitionTaskId = recognitTaskId;
        }
        api(params).then(() => {
          patientInfo.update();
          handleChangeReadOnly();
        });
      })
      .catch(() => {});
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <a onClick={() => handleSecondComfirm(handleCancel, !data?.id)}>{t('取消')}</a>
      <a onClick={() => handleSecondComfirm(handleSubmit)}>{t('保存')}</a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        style={{ padding: 0 }}
        transformText={(text) => (
          <TabHeader
            label={t('手术时间')}
            title={text ? moment(text).format('YYYY-MM-DD') : ''}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
        rules={[requiredRule]}
      >
        <TabHeaderInput label={t('手术时间')} action={action} />
      </FormItem>
      <FormItem name="reportImage" transformText={mapImage}>
        <RecordImgUpload fileType="surgicalRecord" recordId={data?.id} />
      </FormItem>
      <FormItem label={<Label>手术名称</Label>} name="surgeryName">
        <Input maxLength={100} showCount />
      </FormItem>
      <FormItem label={<Label>手术经过</Label>} name="procedures">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>术中所见</Label>} name="seenDuringSurgery">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default SurgicalRecordsTab;
