import request from '@/common/request';

/**
 * @description: 保存图片处理任务
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:53:23
 */
export const httpImageProcessSaveTask = (params: any) => {
  return request.post('/imageProcessing/saveTask', params);
};

/**
 * @description: 删除图片处理任务
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:54:17
 */
export const httpImageProcessDeleteTask = (params: any) => {
  return request.post('/imageProcessing/deleteTask', params);
};

/**
 * @description: 更新图片分类
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:55:00
 */
export const httpImageProcessUpdateClassify = (params: any) => {
  return request.post('/imageProcessing/updateImageClassification', params);
};

/**
 * @description: 查询图片分类列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:55:38
 */
export const httpImageProcessClassify = (params: any) => {
  return request.post('/imageProcessing/imageClassificationList', params);
};

/**
 * @description: 查询图片处理任务详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:56:31
 */
export const httpImageProcessQueryTaskDetails = (params: any) => {
  return request.post('/imageProcessing/queryTaskDetails', params);
};

/**
 * @description: 查询患者图片处理任务编号
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-19 16:57:22
 */
export const httpImageProcessQueryPatientTask = (params: any) => {
  return request.post('/imageProcessing/queryPatientTaskId', params);
};

/**
 * @description: 批量删除识别图片
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-21 14:03:18
 */
export const httpImageProcessBatchDelete = (params: any) => {
  return request.post('/imageProcessing/batchDeleteRecognitionImage', params);
};

/**
 * @description: 提交患者病历智能识别结果至病历
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-22 11:22:13
 */
export const httpPatientSubmitGptResult = (params: any) => {
  return request.post('/patient/submitGptResult', params);
};

/**
 * @description: 获取与患者病历智能识别结果重复的记录
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.0
 * @Date: 2024-11-22 11:23:13
 */
export const httpPatientRepeatRecord = (params: any) => {
  return request.post('/patient/getGptResultRepeatRecord', params);
};

/**
 * @description: 获取患者当前已有的智能识别任务
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.2
 * @Date: 2024-12-19 16:46:44
 */
export const httpImageRecognitionFind = (params: any) => {
  return request.post('/imageRecognition/find', params);
};

/**
 * @description: 创建图片智能识别任务
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.27.2
 * @Date: 2024-12-19 16:48:09
 */
export const httpImageRecognitionCreate = (params: any) => {
  return request.post('/imageRecognition/create', params);
};
