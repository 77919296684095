/* eslint-disable no-shadow */
import { useLocales } from '@/Locales';
import { useScrollDrag, useZoom } from '@/pages/combinedWard/hook/useDrag';
import { useTimelineDetail } from '@/pages/combinedWard/hook/useTimeline';
import SpeechCard from '@/pages/speechLecture/lecture/components/SpeechCard';
import { Empty } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SpeechIssue from '../SpeechIssue';
import TimeAxis from '../TimeAxis';
import AxisHeader from '../TimeAxis/components/AxisHeader';
import TimelineHeader from '../TimelineHeader';
import TimelinePatient from '../TimelinePatient';
import TimelineSubfield from '../TimelineSubfield';
import styles from './index.less';

interface TimelineCasesProps {
  edit?: boolean;
  timeline?: boolean; // true 只有时间轴
  capture?: boolean; // true时用于患者详情下载图片
  direction?: 'horizontal' | 'vertical';
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-09 10:05:49
 */
const TimelineCases: React.FC<TimelineCasesProps> = (props) => {
  const { direction = 'vertical', capture, timeline, edit = false } = props;
  const { t } = useLocales();

  const [params] = useSearchParams();
  // 用于测试生成时间轴图片
  const debugGenera = params.getAll('debug')[0] === '1';
  const [axisDirection, setAxisDirection] = useState<'VERTICAL' | 'HORIZONTAL'>('VERTICAL');

  const timelineSubfieldRef = useRef<any>(null);
  // const [timelineHeight, setTimelineHeight] = useState<number[]>([]);
  // const [lineHeight, setLineHeight] = useRecoilState(timelineHeightAtom);
  // const [openTool, setOpenTool] = useState(true);
  const [hasEdit, setHasEdit] = useState(edit);
  const [saving, setSaving] = useState(false);
  // const heightStore: number[] = [];

  const { sliderRef, isDragging, handleMouseDown, canDrag, setCanDrag } = useScrollDrag();
  const { timelineDetail, timelineList, hasTreatmentRecords, setReloadDetail } =
    useTimelineDetail();
  const { setScaleFactor, scaleFactor, handleZoom, canvasRef, containerRef } = useZoom(
    timelineDetail?.timeLines?.length > 0,
    hasTreatmentRecords,
  );
  // useCenter(sliderRef.current, canvasRef.current);

  /** 生成时间轴图片 */
  // const handleGeneraCodeImg = () => {
  //   if (!debugGenera) return;

  //   const dom: any = document.querySelector('#timelineCanvas');
  //   html2canvas(dom, {
  //     scale: 3, // 可以调整缩放比例
  //     backgroundColor: '#fff', // 设置背景色为白色
  //     useCORS: true, // 如果图片或资源来自不同源，需要启用 CORS
  //     width: dom.scrollWidth,
  //     height: dom.scrollHeight,
  //     windowHeight: dom.scrollHeight * 2,
  //     windowWidth: dom.scrollWidth * 1.5,
  //   }).then((canvas) => {
  //     const saveUrl: any = canvas.toDataURL('image/jpg');
  //     const a: any = document.createElement('a');
  //     document.body.appendChild(a);
  //     a.href = saveUrl;
  //     a.download = `${timelineDetail?.patientInfo?.name}_病例时间轴.jpg`;
  //     a.click();
  //   });
  // };

  // useEffect(() => {
  //   if (timelineHeight.length) {
  //     const max = timelineHeight.reduce((acc, curr) => Math.max(acc, curr), -Infinity);
  //     console.log('max', max);
  //     setLineHeight(max + 28);
  //   }
  // }, [timelineHeight]);

  return (
    <>
      {timeline && (
        <TimelineHeader
          doctorId={timelineDetail?.patientInfo?.doctorId}
          onEdit={setHasEdit}
          onSave={setSaving}
        />
      )}
      <div
        className={styles.timeline}
        style={{
          padding: !timeline ? 0 : 16,
          position: capture ? 'fixed' : 'static',
          right: 999999,
          display: capture ? 'inline-block' : 'block',
        }}
      >
        {timeline && <TimelinePatient edit={hasEdit} canSave={saving} />}
        {timeline && <SpeechIssue edit={hasEdit} />}
        <SpeechCard
          id="baseInfo"
          title="病例时间轴"
          style={{ marginBottom: 0 }}
          without={!timeline}
        >
          <div className={styles['timeline-cases']}>
            {!capture && (
              <AxisHeader
                edit={hasEdit}
                direction={axisDirection}
                onChange={(v) => {
                  setAxisDirection(v);
                  setScaleFactor(1);
                }}
                onUpdate={() => setReloadDetail(true)}
                canDrag={canDrag}
                onSet={setCanDrag}
                onZoom={handleZoom}
                scaleFactor={scaleFactor}
              />
            )}
            <div
              id="timelineCanvas"
              ref={sliderRef}
              className={classNames(
                styles['timeline-canvas'],
                !capture && styles['timeline-canvas-height'],
                hasEdit && !capture && styles['timeline-bgimage'],
              )}
            >
              <div
                className={classNames(
                  styles['timeline-container'],
                  timelineDetail?.timeLines?.length === 0 && styles['no-container'],
                )}
                ref={canvasRef}
                style={{
                  transform: `scale(${scaleFactor})`,
                  position: 'relative',
                  paddingTop: capture ? 30 : 0, // 下载图片时添加边距
                  paddingBottom: capture ? 30 : 0, // 下载图片时添加边距
                  paddingLeft: capture ? 30 : 0, // 下载图片时添加边距
                  paddingRight: capture ? 30 : 0, // 下载图片时添加边距
                  zIndex: 1,
                  transformOrigin: 'center', // 确保缩放基于容器中心
                  userSelect: isDragging ? 'none' : 'auto',
                  cursor: isDragging ? 'grabbing' : 'auto',
                  width: `calc(100% / ${scaleFactor})`, // 根据缩放比例调整宽度
                  height: `calc(100% / ${scaleFactor})`, // 根据缩放比例调整高度
                }}
                onMouseDown={handleMouseDown}
              >
                <div
                  className="white-space"
                  style={{ height: hasEdit ? 100 : 80, flexShrink: 0 }}
                />
                <div ref={containerRef} className={styles.container}>
                  {!capture && timelineDetail?.timeLines?.length > 0 && (
                    <div
                      className="white-space"
                      style={{ width: hasEdit ? 100 : 100, flexShrink: 0 }}
                    />
                  )}
                  <TimeAxis
                    edit={hasEdit}
                    direction={axisDirection}
                    source={timelineList}
                    onRela={(i, y) => timelineSubfieldRef.current?.open(i, y)}
                    onEdit={() => setReloadDetail(true)}
                    onDelete={() => setReloadDetail(true)}
                  />
                  {timelineDetail?.timeLines?.length > 0 && (
                    <div
                      className="white-space"
                      style={{ width: !capture && hasEdit ? 100 : 100, flexShrink: 0 }}
                    />
                  )}
                  {timelineDetail?.timeLines?.length === 0 && hasEdit && (
                    <Empty style={{ width: '100%', padding: '200px 0', margin: '0 auto' }} />
                  )}
                </div>
                {!capture && timelineDetail?.timeLines?.length > 0 && (
                  <div
                    className="white-space"
                    style={{ height: hasEdit ? 200 : 100, flexShrink: 0 }}
                  />
                )}
              </div>
            </div>
          </div>
        </SpeechCard>
      </div>
      <TimelineSubfield ref={timelineSubfieldRef} />
    </>
  );
};

export default TimelineCases;
