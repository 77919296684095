import { request } from '@/common/request';
import { getToken } from '@/utils/cookies';
import { baseURL } from '@/config/base';

export const saveActivityTopic = (params: any) => {
  const url = '/activity/topic/save';
  return request.post(url, params);
};
export const getActivityTopicPage = (params: any) => {
  const url = '/activity/topic/page';
  return request.post(url, params);
};
export const deleteActivityTopic = (params: any) => {
  const url = '/activity/topic/delete';
  return request.post(url, params);
};
export const getActivityPage = (params: any) => {
  const url = '/activity/page';
  return request.post(url, params);
};
export const saveActivity = (params: any) => {
  const url = '/activity/save';
  return request.post(url, params);
};
export const deleteActivity = (params: any) => {
  const url = '/activity/delete';
  return request.post(url, params);
};
export const getActivityDetail = (params: any) => {
  const url = '/activity/detail';
  return request.post(url, params);
};
export const getActivityGenerateId = (params: any) => {
  const url = '/activity/generateQrCode';
  return request.post(url, params);
};
export const getPolyvPage = (params: any) => {
  const url = '/polyv/channel/page';
  return request.post(url, params);
};
export const getActivityUserPage = (params: any) => {
  const url = '/activity/user/page';
  return request.post(url, params);
};
export const exportActivityUser = (params: any) => {
  const url = `${baseURL}/api/activity/user/export`;
  window.open(`${url}?${toQueryString({ ...params, 'X-Token': getToken() })}`);
};
export const getActivityStatisticPage = (params: any) => {
  const url = '/activity/statistic/page';
  return request.post(url, params);
};
export const exportActivityStatistic = (params: any) => {
  const url = `${baseURL}/api/activity/statistic/export`;
  window.open(`${url}?${toQueryString({ ...params, 'X-Token': getToken() })}`);
};
// BI用户登录
export const loginBiUser = () => {
  const url = '/bi/user/info';
  return request.get(url);
};
// 活动用户详情BI页面地址
export const getActivityUserDashboard = (params: any) => {
  const url = '/activity/user/detail/dashboard';
  return request.get(url, params);
};
// 统计详情BI页面地址
export const getActivityStatisticDashboard = (params: any) => {
  const url = '/activity/statistic/dashboard';
  return request.get(url, params);
};
// 运营BI页面地址
export const getActivityOperationDashboard = (params: any) => {
  const url = '/activity/operation/dashboard';
  return request.get(url, params);
};
// 对象转路由参数
export const toQueryString = (obj: any) => {
  const queryString = Object.keys(obj)
    .map((key) => {
      return obj[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}` : '';
    })
    .filter(Boolean)
    .join('&');
  return `${queryString}`;
};
export default {
  saveActivityTopic,
};
