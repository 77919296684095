import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import Empty from '@/pages/patient/detail/components/Empty';
import TimeLine from '@/pages/patient/detail/components/TimeLine';
import { useOcrContextValue } from '@/pages/patient/hooks/useOcr';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ImageOcrParsing from '../../../ImageOcrParsing';
import styles from './index.less';

export const OcrContext = createContext<any>({
  open: false, // 是否打开ocr
  data: null, // 图片地址
  loading: false,
  // ocrRes: null, // ocr识别结果
});

const TabItem = (props: any) => {
  const { fieldKey, label, renderChildren, onDelete } = props;
  const timeLineRef = useRef<any>(null);
  const [timeLine, setTimeLine] = useState<any[]>([]);
  const [selectedDate, setSelectedData] = useState<any>({});
  // const [ocrConfig, setOcrConfig] = useState<any>({});
  // const [controllerRef, setControllerRef] = useState<AbortController | null>(null);
  // const [loading, setLoading] = useState(false);
  // const [reportText, setReportText] = useState(''); // 识别的文本
  // const [finished, setFinished] = useState(false); // 是否识别完成
  // const [ocrDetail, setOcrDetail] = useState<any>([]);
  const permission = useRecoilValue(PatientInfoStatus);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const { t } = useLocales();
  const recordsData = patientInfo[fieldKey] || [];
  const imageOcrParsingRef = useRef<any>(null);

  const { ocrContextValue, ocrConfig } = useOcrContextValue();

  useEffect(() => {
    setTimeLine(
      recordsData.map((item: any) => {
        return {
          label: item.recordTime || item.dischargeTime || item.informationDate,
          value: item.id,
          data: item,
        };
      }),
      // .sort((item: any, nextItem: any) => {
      //   return dayjs(item.label).diff(dayjs(nextItem.label)) * -1;
      // })
    );
  }, [patientInfo]);

  const handleCreate = () => {
    // 通过时间轴插入新数据，再反显到已选择的日期里，判断无id为新增
    timeLineRef.current?.handleCreate();
  };

  const isEmpty = !selectedDate.key;

  // const ocrContextValue = useMemo(() => {
  //   return {
  //     ocrConfig,
  //     setOcrConfig,
  //     ocrDetail,
  //     setOcrDetail,
  //     loading,
  //     setLoading,
  //     controllerRef,
  //     reportText,
  //     setReportText,
  //     setControllerRef,
  //     finished,
  //     setFinished,
  //   };
  // }, [
  //   ocrConfig,
  //   setOcrConfig,
  //   loading,
  //   controllerRef,
  //   setControllerRef,
  //   reportText,
  //   setReportText,
  //   setLoading,
  //   ocrDetail,
  //   setOcrDetail,
  //   finished,
  //   setFinished,
  // ]);

  const content = useMemo(() => {
    if (!selectedDate.key) {
      return null;
    }
    // renderChildren在编辑时不会修改key，所以需要加个时间戳
    return renderChildren(selectedDate.key + Date.now(), selectedDate);
  }, [selectedDate]);

  return (
    <OcrContext.Provider value={ocrContextValue}>
      {isEmpty && <Empty btnText={`${t('新增')}${label}`} onCreate={handleCreate} />}
      <div className={styles.tabBody} style={{ width: isEmpty ? 0 : '100%' }}>
        <div className={styles.left}>
          <TimeLine
            ref={timeLineRef}
            label={label}
            data={timeLine}
            value={selectedDate.key}
            onDelete={onDelete}
            onChange={(v: string, data: any) => {
              imageOcrParsingRef?.current?.closeRequest();
              setSelectedData({ ...data, key: v });
            }}
          />
        </div>
        <div className={styles.right}>{content}</div>
      </div>
      {/* <OcrInfo ocrConfig={ocrConfig} /> */}
      <ImageOcrParsing ref={imageOcrParsingRef} ocrConfig={ocrConfig} />
    </OcrContext.Provider>
  );
};

export default TabItem;
