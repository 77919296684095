import { useLocales } from '@/Locales';
import { patientPPTGenerateV3 } from '@/services/patient';
import { Modal } from '@sinohealth/butterfly-ui-components';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.less';

interface GeneratePPTNotesProps {
  onOk?: () => void;
}

/**
 * @description: PPT内容生成说明
 * @author: KaifengLi
 * @version: v2.29.0
 * @Date: 2025-01-02 15:17:13
 */
const GeneratePPTNotes = forwardRef((props: GeneratePPTNotesProps, ref: any) => {
  const { t } = useLocales();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientId, setPatientId] = useState<string>('');

  useImperativeHandle(ref, () => ({
    open: (id: string) => {
      setOpen(true);
      setPatientId(id);
    },
  }));

  const httpPatientPPTGenerateV3Req = async () => {
    try {
      setLoading(true);
      const res: any = await patientPPTGenerateV3({
        patientId,
      });
      console.log(res);
      const url = `${window.location.pathname}#/combinedWard/pptEditor`;
      window.open(
        `${url}?id=${res.fileId}&patientId=${patientId}&fileName=${res.fileName}&fileServerAddress=${res.fileServerAddress}&documentServerAddress=${res.documentServerAddress}`,
        '_blank',
        'noreferrer',
      );

      setOpen(false);
      props?.onOk?.();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleOk = async () => {
    httpPatientPPTGenerateV3Req();
  };

  return (
    <Modal
      title={t('PPT内容生成说明')}
      width={400}
      className={styles['generate-notes']}
      open={open}
      onOk={handleOk}
      confirmLoading={loading}
      okText={loading ? t('PPT正在生成中, 请稍候...') : t('我已了解，继续生成')}
      onCancel={() => setOpen(false)}
    >
      <div>
        {t(
          'PPT内容将根据您上传的基本信息和详细信息模块中的入院记录、出院记录等内容生成，请确保信息的准确性和完整性！',
        )}
      </div>
    </Modal>
  );
});

export default GeneratePPTNotes;
