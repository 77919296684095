import { useLocales } from '@/Locales';
import { useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import { Button, DatePicker, Form, Input, Modal } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AssociatedDetails from '../AssociatedDetails';
import FormLabel from '../FormLabel';
import { EditFormProps } from '../ImageForm';

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-基因检测信息-FORM
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-10 15:51:41
 */
const GenesForm = forwardRef((props: EditFormProps, ref: any) => {
  const { t } = useLocales();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { patientId, setTimelineParams, setFormParams, formParams, saveSuccess } =
    useTimeLineSave();

  useImperativeHandle(ref, () => ({
    open: (val: any) => {
      setOpen(true);
      form.setFieldsValue({
        ...val,
        recordTime: val.recordTime ? dayjs(val.recordTime) : null,
      });
      setFormParams(val);
    },
  }));

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      setTimelineParams({
        patientId,
        timeLineList: [
          {
            dateStr: formValue.recordTime.format('YYYY-MM'),
            detectionRecords: [
              {
                ...formParams,
                ...formValue,
                recordTime: formValue.recordTime.format('YYYY-MM-DD'),
                nodeType: 'GENETIC_TESTING',
              },
            ],
          },
        ],
      });
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      props?.onSubmit?.();
      setOpen(false);
    }
  }, [saveSuccess]);

  return (
    <>
      {props?.button && (
        <Button
          style={{
            color: '#43A314',
            borderColor: '#43A314',
            background: '#ecf5e7',
            display: 'flex',
            alignItems: 'center',
          }}
          ghost
          icon={<span className="iconfont icon-jiyin" style={{ marginRight: 6 }} />}
          onClick={() => {
            props?.edit && setOpen(true);
          }}
        >
          基因检测信息
        </Button>
      )}
      <Modal
        title={`${formParams?.id ? t('编辑') : t('新增')} ${t('基因检测信息')}`}
        open={open}
        maskClosable={false}
        onOk={handleSubmit}
        style={{ top: 20 }}
        onCancel={() => setOpen(false)}
        afterClose={() => {
          form.resetFields();
          setFormParams({});
        }}
      >
        <Form
          name="basic"
          layout="vertical"
          colon={false}
          form={form}
          labelCol={{ span: 12 }}
          autoComplete="off"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="recordTime"
            rules={[{ required: true, message: t('请选择检查时间') }]}
            label={<FormLabel label="检查时间" />}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={<FormLabel label="样本类型" />} name="sampleType">
            <Input placeholder={t('请输入')} />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="结果描述" />}
            name="description"
            rules={[{ required: true, message: t('请输入结果描述') }]}
          >
            <Input.TextArea
              maxLength={1000}
              showCount
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder={t('请输入结果描述')}
            />
          </Form.Item>
          <Form.Item label={<FormLabel label="关联档案详情" tooltip />} name="refContent">
            <AssociatedDetails
              refContent={formParams?.refContent}
              refId={formParams?.refId}
              refType={formParams?.refType}
              onSubmit={(id, type, time) => {
                setFormParams({
                  ...formParams,
                  refId: id || undefined,
                  refType: type || undefined,
                  refContent: time || undefined,
                });
                form.setFieldsValue({ refContent: time });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default GenesForm;
