import { useLocales } from '@/Locales';
import { cosResourceUploadFile } from '@/services/common';
import { message } from '@sinohealth/butterfly-ui-components';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

const defaultPlugins = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'code',
  'help',
  'wordcount',
];
const TinymceEditor = (props: any) => {
  const { value, onChange, ...other } = props;
  const editorRef = useRef<any>(null);
  const { locales, t } = useLocales();

  const onInit = (evt: any, editor: any) => {
    editorRef.current = editor;
  };
  const handleChange = () => {
    const val = editorRef.current.getContent();
    onChange && onChange(val);
  };
  // 上传图片
  const ImagesUploadHandler = async (blobInfo: any, success: any, failFun: any) => {
    const size = blobInfo.blob().size / 1024 / 1024;
    if (size > 10) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('图片大小不能超过10MB');
      });
    }
    const formData: any = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    const imgSrc: any = await cosResourceUploadFile(formData);
    return new Promise((resolve, reject) => {
      resolve(imgSrc);
    });
  };

  // 上传视频
  const filePickerCallback = async (cb: any, values: any, meta: any) => {
    // media媒体
    if (meta.filetype === 'media') {
      // 模拟上传本地视频
      const input: any = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/*');
      input.onchange = async (e: any) => {
        const file: any = input.files[0];
        const size = file.size / 1024 / 1024;
        if (size > 15) {
          message.error('视频大小不能超过15MB');
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
          const videoUrl: any = await cosResourceUploadFile(formData);
          cb(videoUrl); // 插入
        } catch (err) {
          console.log(err);
          message.error('上传出错了');
        }
      };
      input.click();
    }
  };

  const initParams = {
    height: 500,
    menubar: false,
    language: locales === 'zh-cn' ? 'zh_CN' : 'en_US',
    toolbar:
      'undo redo | blocks | ' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    contextmenu: 'link | image',
    image_caption: true,
    paste_data_images: true,
    file_picker_types: 'media',
    convert_urls: false,
    images_upload_handler: ImagesUploadHandler,
    file_picker_callback: filePickerCallback,
    ...other, // 其他配置
    plugins: other.plugins ? [...defaultPlugins, ...other.plugins] : defaultPlugins,
  };
  return (
    <Editor
      id="tinymce"
      onInit={onInit}
      initialValue={value}
      onBlur={handleChange}
      init={{
        ...initParams,
        content_style: 'a { color: blue; cursor: pointer; text-decoration: underline; }',
      }}
    />
  );
};

export default TinymceEditor;
